import { CloseCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Button, Form, Input, message } from "antd";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { allActions } from "../../../Redux/myActions";
import { IoMdMusicalNotes } from "react-icons/io";

export default function VideoTemplateForm({
  current,
  setCurrent,
  formData,
  setFormData,
  selectedImage,
  setSelectedImage,
  activeTemplate,
}) {
  const [hashTagList, setHashTagList] = useState([]);
  const [form] = Form.useForm();
  const imageRef = useRef();
  const dispatch = useDispatch();
  const [activeMusic, setActiveMusic] = useState("");
  const [activeVideo, setActiveVideo] = useState("");

  const addHashTag = () => {
    const whitespaceregex = /^\s*$/;
    const temp = form.getFieldValue("hash_tag");
    whitespaceregex.test(temp)
      ? message.warning("Please enter Hashtag")
      : setHashTagList((prev) => [...prev, temp]);
    form.resetFields(["hash_tag"]);
  };

  const handleUploadImage = (event) => {
    const fileList = Array?.from(event.target.files);
    const formData = new FormData();
    fileList?.map((file, index) => formData.append(`files[]`, file));

    dispatch(
      allActions(formData, {
        method: "post",
        endPoint: "upload_post_image",
        attempt: "EDIT_UPLOAD_PROPERTY_IMAGE_REQUEST_ATTEMPT",
        success: "EDIT_UPLOAD_PROPERTY_IMAGE_REQUEST_SUCCESS",
        failure: "EDIT_UPLOAD_PROPERTY_IMAGE_REQUEST_FAILURE",

        saveBearerToken: false,
        multipartFormData: true,
        successInternalState: (res) => {
          setFormData((prev) => {
            return {
              ...prev,
              image_list: [
                ...prev?.image_list,
                ...res?.source?.map((image) => image?.image_name),
              ],
            };
          });
        },
        failureInternalState: () => {},
      })
    );
  };

  const handleAddMusic = (value) => {
    setActiveMusic(value);
    setFormData((prev) => {
      return {
        ...prev,
        music: value,
      };
    });
  };

  const handleAddVideo = (value) => {
    setActiveVideo(value);
    setFormData((prev) => {
      return {
        ...prev,
        video_theme: value,
      };
    });
  };

  return (
    <div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 3fr",
          gap: "0.5rem",
          margin: "0 0 1rem",
          width: "100%",
        }}
      >
        <div style={{ gridColumn: "span 4" }}>
          Select {activeTemplate} Image
        </div>
        <div
          style={{
            border: "1px dashed #1677ff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#f5f5f560",
            margin: "0.3rem",
          }}
        >
          <input
            type="file"
            accept=".png, .jpg, .jpeg, .gif"
            multiple
            onChange={handleUploadImage}
            ref={imageRef}
            style={{ display: "none" }}
          />
          <Button
            onClick={() => imageRef?.current?.click()}
            style={{
              border: "1px solid #1677ff",
              color: "#1677ff",
              background: "#f5f5f560",
            }}
          >
            Upload Image
          </Button>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            gap: "0.5rem",
            maxHeight: "20rem",
            overflowY: "scroll",
            background: "#80808010",
            padding: "0.3rem",
            width: "100%",
          }}
        >
          {formData?.image_list?.map((image, index) => (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "0.3rem",
                background: "#80808020",
                gap: "0.5rem",
                width: "100%",
                alignItems: "center",
              }}
            >
              <img
                src={image}
                alt=""
                style={{ width: "100%", height: "10rem", objectFit: "cover" }}
              />
              {selectedImage?.includes(image) ? (
                <Button
                  type="primary"
                  danger
                  style={{ width: "100%" }}
                  onClick={() => {
                    setSelectedImage((prev) => [
                      ...prev?.filter((img, ind) => image !== img),
                    ]);
                  }}
                >
                  Remove
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    selectedImage?.length < activeTemplate
                      ? setSelectedImage((prev) => [...prev, image])
                      : message?.warning(
                          `You have selected ${activeTemplate} Image`
                        );
                  }}
                  type="primary"
                  style={{ width: "100%" }}
                >
                  Select Image
                </Button>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="music-container">
        <span style={{ gridColumn: "span 4" }}>Select Video Theme</span>
        <div
          className={activeVideo == 1 ? "active-music-card" : "music-card"}
          onClick={() => handleAddVideo(1)}
        >
          <IoMdMusicalNotes style={{ fontSize: "1rem" }} /> Video Theme 1
        </div>
        <div
          className={activeVideo == 2 ? "active-music-card" : "music-card"}
          onClick={() => handleAddVideo(2)}
        >
          <IoMdMusicalNotes tyle={{ fontSize: "1rem" }} /> Video Theme 2
        </div>
        <div
          className={activeVideo == 3 ? "active-music-card" : "music-card"}
          onClick={() => handleAddVideo(3)}
        >
          <IoMdMusicalNotes tyle={{ fontSize: "1rem" }} />
          Video Theme 3
        </div>
        <div
          className={activeVideo == 4 ? "active-music-card" : "music-card"}
          onClick={() => handleAddVideo(4)}
        >
          <IoMdMusicalNotes tyle={{ fontSize: "1rem" }} />
          Video Theme 4
        </div>
      </div>

      <div className="music-container">
        <span style={{ gridColumn: "span 4" }}>Select Music</span>
        <div
          className={activeMusic == 1 ? "active-music-card" : "music-card"}
          onClick={() => handleAddMusic(1)}
        >
          <IoMdMusicalNotes style={{ fontSize: "1rem" }} /> Music 1
        </div>
        <div
          className={activeMusic == 2 ? "active-music-card" : "music-card"}
          onClick={() => handleAddMusic(2)}
        >
          <IoMdMusicalNotes tyle={{ fontSize: "1rem" }} /> Music 2
        </div>
        <div
          className={activeMusic == 3 ? "active-music-card" : "music-card"}
          onClick={() => handleAddMusic(3)}
        >
          <IoMdMusicalNotes tyle={{ fontSize: "1rem" }} /> Music 3
        </div>
        <div
          className={activeMusic == 4 ? "active-music-card" : "music-card"}
          onClick={() => handleAddMusic(4)}
        >
          <IoMdMusicalNotes tyle={{ fontSize: "1rem" }} /> Music 4
        </div>
      </div>
      <Form
        form={form}
        initialValues={{ ...formData }}
        layout="vertical"
        className="video-template-form"
      >
        <Form.Item style={{ margin: "0" }} name="video_name" label="Video Name">
          <Input placeholder="Video Name" />
        </Form.Item>
        <Form.Item style={{ margin: "0" }} name="caption" label="Caption">
          <Input placeholder="Caption" />
        </Form.Item>
        <div style={{ gridColumn: "span 2" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "0.5rem",
              alignItems: "flex-end",
            }}
          >
            <Form.Item
              style={{
                margin: "0",
                width: "50%",
              }}
              name="hash_tag"
              label="Hash Tag"
            >
              <Input placeholder="Hash Tag" />
            </Form.Item>
            <PlusCircleOutlined
              onClick={addHashTag}
              style={{
                color: "#1677ff",
                fontSize: "2rem",
                cursor: "pointer",
              }}
            />
          </div>
          {hashTagList?.length > 0 && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "0.5rem",
                cursor: "pointer",
                flexWrap: "wrap",
                margin: "1rem 0 0",
                maxHeight: "6rem",
                overflowY: "scroll",
                background: "#80808010",
                padding: "0.5rem",
              }}
            >
              {hashTagList?.map((item, index) => (
                <span
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "0.5rem",
                    alignItems: "center",
                    padding: "0.2rem 0.5rem",
                    borderRadius: "0.5rem",
                    background: "#80808025",
                  }}
                >
                  {item}
                  <CloseCircleOutlined
                    onClick={() =>
                      setHashTagList((prev) => [
                        ...prev?.filter((tag, ind) => index !== ind),
                      ])
                    }
                    className="hashtag-class"
                  />
                </span>
              ))}
            </div>
          )}
        </div>
        <div style={{ gridColumn: "span 2" }}>
          <Form.Item
            style={{ margin: "0" }}
            name="description"
            label="Description"
          >
            <Input.TextArea rows={6} placeholder="Description" />
          </Form.Item>
        </div>
        <div style={{ gridColumn: "span 2", display: "flex", gap: "0.5rem" }}>
          <Button onClick={() => setCurrent((prev) => prev - 1)} type="primary">
            Back
          </Button>
          <Button onClick={() => setCurrent((prev) => prev + 1)} type="primary">
            Submit
          </Button>
        </div>
      </Form>
    </div>
  );
}
