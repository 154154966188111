import React, { useState } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { Divider, Modal, Table, Tag, message } from "antd";
import { useDispatch, connect } from "react-redux";
import { allActions } from "../../Redux/myActions";
import { useEffect } from "react";
import MainLoader from "../loader/MainLoader";

const mapStateToProps = (store) => {
  const { PropertyReducer } = store;
  return { PropertyReducer };
};

const PostImageModal = ({
  setIsModalOpen,
  isModalOpen,
  modalData,
  postId,
  PropertyReducer,
  fetchPropertyList,
}) => {
  const dispatch = useDispatch();
  const { adImageList, loading, imageloading } = PropertyReducer;

  useEffect(() => {
    fetchImageList();
  }, []);

  const fetchImageList = () => {
    dispatch(
      allActions(
        {},
        {
          method: "get",
          // endPoint: `ads_images/${postId}`,
          endPoint: `post_images/${postId}`,
          attempt: "FETCH_IMAGE_LIST_REQUEST_ATTEMPT",
          success: "FETCH_IMAGE_LIST_REQUEST_SUCCESS",
          failure: "FETCH_IMAGE_LIST_REQUEST_FAILURE",
          saveBearerToken: false,
          successInternalState: (res) => {},
          failureInternalState: () => {},
        }
      )
    );
  };

  const handleOk = () => {
    setIsModalOpen(false);
    fetchPropertyList();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    fetchPropertyList();
  };

  const columns = [
    {
      title: "Image",
      align: "center",
      dataIndex: "image_name",
      render: (image) => (
        <img
          style={{ width: "5rem", height: "5rem", objectFit: "contain" }}
          src={image}
          alt=""
        />
      ),
    },
    {
      title: "Default",
      align: "center",
      dataIndex: "featured",
      render: (_, data) => {
        return Number(data?.featured) === 1 ? (
          <Tag color="green">Yes</Tag>
        ) : (
          <Tag
            onClick={() => handleMakeDefualtImage(data)}
            style={{ cursor: "pointer" }}
            color="orange"
          >
            Make It Default
          </Tag>
        );
      },
    },
    {
      title: "Status",
      align: "center",
      dataIndex: "status",
      render: (status) => {
        return Number(status) === 1 ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        );
      },
    },
    {
      title: "Option",
      align: "center",
      dataIndex: "delete",
      render: (_, data) => (
        <DeleteOutlined
          onClick={() => handleMakeDeleteImage(data)}
          style={{ fontSize: "1.5rem", cursor: "pointer", color: "red" }}
        />
      ),
    },
  ];

  const handleMakeDefualtImage = (data) => {
    dispatch(
      allActions(
        {
          postid: Number(data?.post_id),
          imageid: Number(data?.image_id),
        },
        {
          method: "post",
          endPoint: `make_image_default`,
          attempt: "POST_DEFAULT_REQUEST_ATTEMPT",
          success: "POST_DEFAULT_REQUEST_SUCCESS",
          failure: "POST_DEFAULT_REQUEST_FAILURE",
          saveBearerToken: false,
          successInternalState: () => {
            setTimeout(() => {
              fetchImageList();
            }, 500);
          },
          failureInternalState: () => {},
        }
      )
    );
  };

  const handleMakeDeleteImage = (data) => {
    Number(data?.featured) === 1
      ? message.error("Defualt Image can't be deleted")
      : dispatch(
          allActions(
            {
              image_name: data?.image_name,
            },
            {
              method: "post",
              endPoint: `remove_image`,
              attempt: "DELETE_IMAGE_REQUEST_ATTEMPT",
              success: "DELETE_IMAGE_REQUEST_SUCCESS",
              failure: "DELETE_IMAGE_REQUEST_FAILURE",
              saveBearerToken: false,
              successInternalState: () => {
                setTimeout(() => {
                  fetchImageList();
                }, 500);
              },
              failureInternalState: () => {},
            }
          )
        );
  };

  return (
    <Modal
      title="My Ads"
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={false}
    >
      {imageloading && <MainLoader />}
      <Divider />
      <Table
        scroll={{ y: 400 }}
        pagination={false}
        dataSource={adImageList}
        columns={columns}
      />
    </Modal>
  );
};

export default connect(mapStateToProps)(PostImageModal);
