import React from "react";
import { Pie } from "@ant-design/plots";
import { Card } from "antd";

export const PieChart = ({ data }) => {
  const config = {
    appendPadding: 10,
    data,
    angleField: "value",
    colorField: "type",
    theme: "light",
    radius: 0.8,
    innerRadius: 0.6,
    label: {
      type: "inner",
      offset: "-50%",
      content: "{value}",
      style: {
        textAlign: "center",
        fontSize: 18,
        color: "red",
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: "pre-wrap",
          textOverflow: "ellipsis",
        },
      },
    },
    pieStyle: {
      lineWidth: 0,
    },
  };
  return (
    <Card
      hoverable
      style={{
        // height: "12rem",
        padding: "0.5rem !important",
      }}
    >
      <Pie
        style={{
          height: "10rem",
          marginTop: "-0.5rem",
        }}
        {...config}
      />
    </Card>
  );
};
