import React, { useEffect } from "react";
import { Button, Checkbox, Divider, Form, Input, Select, Skeleton } from "antd";
import { useDispatch, connect } from "react-redux";

import DashboardLayout from "../../layout/Layout";
import Breadcrumb from "../../component/breedcrumb/Breadcrumb";
import { allActions } from "../../Redux/myActions";

const mapStateToProps = (store) => {
  const ProfileReducer = store?.ProfileReducer;
  return { ProfileReducer };
};

const PrivacySetting = ({ ProfileReducer }) => {
  const dispatch = useDispatch();
  const {
    loading,
    privacySettingList,
    privacyloading,
    deactivateloading,
    daectivateReasonList,
  } = ProfileReducer;
  const crumbList = [
    {
      title: "Dashboard",
      link: "/dashboard",
    },
    {
      title: "Privacy Setting",
      link: "/privacy-setting",
    },
  ];

  useEffect(() => {
    dispatch(
      allActions(
        {},
        {
          method: "get",
          endPoint: "privacy_setting",
          attempt: "GET_PRIVACY_SETTING_REQUEST_ATTEMPT",
          success: "GET_PRIVACY_SETTING_REQUEST_SUCCESS",
          failure: "GET_PRIVACY_SETTING_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: () => {},
          failureInternalState: () => {},
        }
      )
    );

    dispatch(
      allActions(
        {},
        {
          method: "get",
          endPoint: "account_deactivate_reason",
          attempt: "GET_DEACTIVATE_REASON_REQUEST_ATTEMPT",
          success: "GET_DEACTIVATE_REASON_REQUEST_SUCCESS",
          failure: "GET_DEACTIVATE_REASON_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: () => {},
          failureInternalState: () => {},
        }
      )
    );
  }, []);

  return (
    <DashboardLayout>
      <Breadcrumb title="Privacy Setting" crumbList={crumbList} />
      <div className="privacy--deactivate--setting--container">
        {privacyloading && !loading ? (
          <div className="privacy--container ">
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                flexBasis: "50%",
              }}
            >
              <Skeleton.Input active />
              <Skeleton.Input active block="block" />
              <Skeleton.Input active block="block" />
              <Skeleton.Input active block="block" />
              <Skeleton.Input active block="block" />
              <Skeleton.Input active block="block" />
              <Skeleton.Input active block="block" />

              <Skeleton.Input style={{ borderRadius: "3rem" }} active />
            </div>
          </div>
        ) : (
          <PrivacyComponent
            privacyList={privacySettingList}
            loading={loading}
            privacyloading={privacyloading}
          />
        )}

        {deactivateloading && !loading ? (
          <div className="deactivate--container">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flexBasis: "50%",

                gap: "1rem",
              }}
            >
              <Skeleton.Input active />
              <Skeleton.Input active block="block" />
              <Skeleton.Input
                active
                style={{ height: "8rem", margin: "2rem 0" }}
                block="block"
              />
              <Skeleton.Input style={{ borderRadius: "3rem" }} active />
            </div>
          </div>
        ) : (
          <DeactivateComponent
            deactivateloading={deactivateloading}
            deactivationList={daectivateReasonList}
            loading={loading}
          />
        )}
      </div>
    </DashboardLayout>
  );
};

export default connect(mapStateToProps)(PrivacySetting);

export const PrivacyComponent = ({ privacyList, loading, privacyloading }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      setting_id: privacyList?.setting_id,
      user_id: privacyList?.user_id,
      profile_photo: privacyList?.profile_photo === "1" ? true : false,
      monthly_newsletter:
        privacyList?.monthly_newsletter === "1" ? true : false,
      email_notification_offer:
        privacyList?.email_notification_offer === "1" ? true : false,
      email_alert_new_listing:
        privacyList?.email_alert_new_listing === "1" ? true : false,
      enable_offer: privacyList?.enable_offer === "1" ? true : false,
      push_notification: privacyList?.push_notification === "1" ? true : false,
      property_updates: privacyList?.property_updates === "1" ? true : false,
      sale_result: privacyList?.sale_result === "1" ? true : false,
    });
  }, []);

  const handleUpdatePrivacySetting = (values) => {
    dispatch(
      allActions(
        {
          setting_id: privacyList?.setting_id,
          user_id: privacyList?.user_id,
          profile_photo:
            values?.profile_photo === true || values?.profile_photo === "1"
              ? "1"
              : "0",
          monthly_newsletter:
            values?.monthly_newsletter === true ||
            values?.monthly_newsletter === "1"
              ? "1"
              : "0",
          email_notification_offer:
            values?.email_notification_offer === true ||
            values?.email_notification_offer === "1"
              ? "1"
              : "0",
          email_alert_new_listing:
            values?.email_alert_new_listing === true ||
            values?.email_alert_new_listing === "1"
              ? "1"
              : "0",
          enable_offer:
            values?.enable_offer === true || values?.enable_offer === "1"
              ? "1"
              : "0",
          push_notification:
            values?.push_notification === true ||
            values?.push_notification === "1"
              ? "1"
              : "0",
          property_updates:
            values?.property_updates === true ||
            values?.property_updates === "1"
              ? "1"
              : "0",
          sale_result:
            values?.sale_result === true || values?.sale_result === "1"
              ? "1"
              : "0",
        },
        {
          method: "post",
          endPoint: "privacy_setting",
          attempt: "POST_PRIVACY_SETTING_REQUEST_ATTEMPT",
          success: "POST_PRIVACY_SETTING_REQUEST_SUCCESS",
          failure: "POST_PRIVACY_SETTING_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: () => {},
          failureInternalState: () => {},
        }
      )
    );
  };

  return (
    <div className="privacy--container">
      <h3 style={{ padding: "1rem" }}>Privacy Settings</h3>
      <Divider style={{ margin: "0" }} />
      <Form
        onFinish={handleUpdatePrivacySetting}
        form={form}
        labelCol={{ span: 20 }}
        labelAlign="left"
        style={{ padding: "1rem 1.5rem" }}
      >
        <Form.Item name="profile_photo" valuePropName="checked">
          <Checkbox>Make My Profile Photo Public</Checkbox>
        </Form.Item>

        <Form.Item name="monthly_newsletter" valuePropName="checked">
          <Checkbox>I Want To Receive Monthly Newsletter</Checkbox>
        </Form.Item>
        <Form.Item name="email_notification_offer" valuePropName="checked">
          <Checkbox>
            I Want To Receive E-Mail Notifications Of Offers/Messages
          </Checkbox>
        </Form.Item>

        <Form.Item name="email_alert_new_listing" valuePropName="checked">
          <Checkbox>
            I Want To Receive E-Mail Alerts About New Listings
          </Checkbox>
        </Form.Item>

        <Form.Item name="enable_offer" valuePropName="checked">
          <Checkbox>Enable Offers/Messages Option In All My Ads Post</Checkbox>
        </Form.Item>

        <Form.Item name="push_notification" valuePropName="checked">
          <Checkbox>Enable Daily Mail</Checkbox>
        </Form.Item>
        <Button
          loading={loading && privacyloading}
          type="primary"
          htmlType="submit"
          className="button--modal"
        >
          {loading && privacyloading ? "Loading" : "Save Changes"}
        </Button>
      </Form>
    </div>
  );
};

export const DeactivateComponent = ({
  deactivationList,
  loading,
  deactivateloading,
}) => {
  const dispatch = useDispatch();
  const handleSubmitDeactivationReason = (values) => {
    dispatch(
      allActions(values, {
        method: "post",
        endPoint: "deactivate_account",
        attempt: "POST_DEACTIVATE_REASON_REQUEST_ATTEMPT",
        success: "POST_DEACTIVATE_REASON_REQUEST_SUCCESS",
        failure: "POST_DEACTIVATE_REASON_REQUEST_FAILURE",

        saveBearerToken: false,
        successInternalState: () => {},
        failureInternalState: () => {},
      })
    );
  };
  return (
    <div className="deactivate--container">
      <h3 style={{ fontWeight: 500 }}>Deactivate Account</h3>
      <Divider style={{ margin: "0.5rem 0" }} />
      <Form
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
        onFinish={handleSubmitDeactivationReason}
        layout="vertical"
      >
        <div>
          <Form.Item
            size="large"
            rules={[
              {
                required: true,
                message: "Deactivation reason is required",
              },
            ]}
            name="reason"
            label="Reason for Deactivate"
          >
            <Select
              style={{ width: "100%" }}
              placeholder="Select Reason"
              options={[
                ...deactivationList?.map((item) => {
                  return {
                    value: item?.name,
                    label: item?.name,
                  };
                }),
              ]}
            />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Deactivation description is required",
              },
            ]}
            name="description"
            label="Do you have anything to say?"
          >
            <Input.TextArea
              style={{
                padding: "1rem",
                outline: "none",
                width: "100%",
                borderRadius: "4px",
              }}
              placeholder="Write your message here..."
              rows={6}
            />
          </Form.Item>
        </div>

        <Button
          loading={loading && deactivateloading}
          type="primary"
          danger
          htmlType="submit"
          // className="button--modal"
          style={{ width: "fit-content" }}
        >
          {loading && deactivateloading ? "Loading" : "Deactivate Account"}
        </Button>
      </Form>
    </div>
  );
};
