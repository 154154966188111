import React from "react";

import { connect, useDispatch } from "react-redux";
import { Button, Divider, Form, InputNumber } from "antd";

import DashboardLayout from "../../layout/Layout";
import Breadcrumb from "../../component/breedcrumb/Breadcrumb";
import { allActions } from "../../Redux/myActions";

const mapStateToProps = (store) => {
  const AgentAgencyReducer = store.AgentAgencyReducer;
  return { AgentAgencyReducer };
};

const Coupon = ({ AgentAgencyReducer }) => {
  const dispatch = useDispatch();
  const { loading } = AgentAgencyReducer;

  const crumbList = [
    {
      title: "Dashboard",
      link: "/dashboard",
    },
    {
      title: "Coupon",
      link: "/coupon",
    },
  ];

  const handleSubmitCoupon = (values) => {
    dispatch(
      allActions(
        { code: values?.code.toString(), status: 1 },
        {
          method: "post",
          endPoint: "add_code",
          attempt: "COUPON_ADD_REQUEST_ATTEMPT",
          success: "COUPON_ADD__REQUEST_SUCCESS",
          failure: "COUPON_ADD_REQUEST_FAILURE",

          saveBearerToken: true,
          successInternalState: () => {},
          failureInternalState: () => {},
        }
      )
    );
  };
  return (
    <DashboardLayout>
      <Breadcrumb title="Coupon" crumbList={crumbList} />
      <div className="coupon--container">
        <h3>Add Coupon Code </h3>
        <Divider style={{ margin: "1rem 0 0" }} />
        <Form
          className="coupon--form"
          style={{ width: "70%" }}
          onFinish={handleSubmitCoupon}
        >
          <Form.Item
            name="code"
            rules={[
              {
                required: true,
                message: "Coupon Code is required",
              },
            ]}
          >
            <InputNumber
              type="number"
              size="large"
              style={{ width: "100%", paddingLeft: "0.5rem" }}
              placeholder="Code*"
            />
          </Form.Item>
          <Button
            disabled={loading}
            className="button--modal"
            style={{ width: "100%" }}
            type="primary"
            htmlType="submit"
          >
            Submit
          </Button>
        </Form>
      </div>
    </DashboardLayout>
  );
};

export default connect(mapStateToProps)(Coupon);
