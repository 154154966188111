import { Button, message } from "antd";
import React, { useState } from "react";

export default function VideoTemplate({
  activeTemplate,
  setActiveTemplate,
  current,
  setCurrent,
}) {
  const handleSubmitTemplate = () => {
    activeTemplate
      ? setCurrent((prev) => prev + 1)
      : message?.warning("Please Select the Template");
  };
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr 1fr",
        gap: "1rem",
      }}
    >
      <div
        onClick={() => setActiveTemplate(1)}
        className={
          activeTemplate === 1
            ? "active-video-template-card"
            : "video-template-card"
        }
      >
        Single Image Template
      </div>
      <div
        onClick={() => setActiveTemplate(2)}
        className={
          activeTemplate === 2
            ? "active-video-template-card"
            : "video-template-card"
        }
      >
        2 Image Template
      </div>
      <div
        onClick={() => setActiveTemplate(3)}
        className={
          activeTemplate === 3
            ? "active-video-template-card"
            : "video-template-card"
        }
      >
        3 Image Template
      </div>
      <div
        onClick={() => setActiveTemplate(4)}
        className={
          activeTemplate === 4
            ? "active-video-template-card"
            : "video-template-card"
        }
      >
        4 Image Template
      </div>
      <div
        onClick={() => setActiveTemplate(5)}
        className={
          activeTemplate === 5
            ? "active-video-template-card"
            : "video-template-card"
        }
      >
        5 Image Template
      </div>
      <div
        onClick={() => setActiveTemplate(6)}
        className={
          activeTemplate === 6
            ? "active-video-template-card"
            : "video-template-card"
        }
      >
        6 Image Template
      </div>
      <div
        onClick={() => setActiveTemplate(7)}
        className={
          activeTemplate === 7
            ? "active-video-template-card"
            : "video-template-card"
        }
      >
        7 Image Template
      </div>
      <div
        onClick={() => setActiveTemplate(8)}
        className={
          activeTemplate === 8
            ? "active-video-template-card"
            : "video-template-card"
        }
      >
        8 Image Template
      </div>
      <div
        onClick={() => setActiveTemplate(9)}
        className={
          activeTemplate === 9
            ? "active-video-template-card"
            : "video-template-card"
        }
      >
        9 Image Template
      </div>
      <div
        onClick={() => setActiveTemplate(10)}
        className={
          activeTemplate === 10
            ? "active-video-template-card"
            : "video-template-card"
        }
      >
        10 Image Template
      </div>
      <div style={{ gridColumn: "span 4" }}>
        <Button onClick={handleSubmitTemplate} type="primary">
          Next
        </Button>
      </div>
    </div>
  );
}
