import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { message } from "antd";
import { useDispatch, connect } from "react-redux";

import OtpModal from "../../component/OtpModal";
import { allActions } from "../../Redux/myActions";
import LoaderPrimary from "../../component/loader/LoaderPrimary";

import logo3 from "../../assets/classiEstateAdmin3.png";

const mapStateToProps = (store) => {
  const AuthReducer = store.AuthReducer;
  return AuthReducer;
};

const Verifyotp = (AuthReducer) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [otpData, setOtpData] = useState({});
  const [userEmail, setUserEmail] = useState("");
  const { loading } = AuthReducer;

  useEffect(() => {
    const isSuccessOtp = location?.state?.isSuccessOtp;
    isSuccessOtp !== true && navigate("/");

    const userEmail = localStorage.getItem("userEmail");
    setUserEmail(userEmail);
  }, []);

  const handleResendOtp = () => {
    dispatch(
      allActions(
        {
          email: userEmail,
        },
        {
          method: "post",
          endPoint: "resend_activation_code",
          attempt: "RESENTOTP_REQUEST_ATTEMPT",
          success: "RESENTOTP_REQUEST_SUCCESS",
          failure: "RESENDOTP_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: () => {
            // localStorage.removeItem("userEmail");
            // navigate("/");
          },
          failureInternalState: () => {},
        }
      )
    );
  };

  const handleSubmitOtp = () => {
    const otpValue = Object.values(otpData).join("");
    otpValue.length != 4
      ? message.error("OTP must be 4 characters long")
      : dispatch(
          allActions(
            {
              email: userEmail,
              code: otpValue,
            },
            {
              method: "post",
              endPoint: "activate_user",
              attempt: "VERIFYOTP_REQUEST_ATTEMPT",
              success: "VERIFYOTP_REQUEST_SUCCESS",
              failure: "VERIFYOTP_REQUEST_FAILURE",

              saveBearerToken: false,
              successInternalState: () => {
                localStorage.removeItem("userEmail");
                navigate("/");
              },
              failureInternalState: () => {},
            }
          )
        );
  };

  return (
    <div className="verifyotp--container">
      <div className="verifyotp--container--body">
        {loading && <LoaderPrimary />}
        <img
          src={logo3}
          style={{
            padding: "0.3rem",
            width: "300px",
            height: "auto",
          }}
          alt="classiEstateLogo"
        />
        <div className="otp--header--container">
          <span>Please verify the otp send on</span>{" "}
          <span className="otp--useremail">{userEmail}</span>
        </div>
        <OtpModal setOtpData={setOtpData} />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
          }}
        >
          <span
            disabled={loading}
            onClick={handleResendOtp}
            className="resend--otp"
          >
            RESEND OTP
          </span>
          <button
            disabled={loading}
            // className="otp--verify--button"
            className="button--modal"
            style={{ border: "0", width: "15rem" }}
            onClick={handleSubmitOtp}
          >
            Verify OTP
          </button>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(Verifyotp);
