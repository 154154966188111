import React, { useEffect } from "react";
import { Button, Table } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useDispatch, connect } from "react-redux";

import DashboardLayout from "../../layout/Layout";
import Breadcrumb from "../../component/breedcrumb/Breadcrumb";
import { allActions } from "../../Redux/myActions";
import { IsScrollHeight } from "../../Helpers/FrontendHelper";
import MainLoader from "../../component/loader/MainLoader";
import moment from "moment";

const crumbList = [
  {
    title: "Dashboard",
    link: "/dashboard",
  },
  {
    title: "Agent Inqury",
    link: "/offer-message/agent-inqury",
  },
];

const mapStateToProps = (store) => {
  const AgentAgencyReducer = store?.AgentAgencyReducer;
  return { AgentAgencyReducer };
};

const AgentInquiry = ({ AgentAgencyReducer }) => {
  const { loading, agentInquiryList } = AgentAgencyReducer;
  const dispatch = useDispatch();
  const isSticky = IsScrollHeight();

  useEffect(() => {
    fetchAgentInquiryList(1);
  }, []);

  const fetchAgentInquiryList = (pageNo) => {
    dispatch(
      allActions(
        {},
        {
          method: "get",
          endPoint: `agent_enquiry?api_from=classiEstate&page=${pageNo}`,
          attempt: "FETCH_AGENTINQUIRY_LIST_REQUEST_ATTEMPT",
          success: "FETCH_AGENTINQUIRY_LIST_REQUEST_SUCCESS",
          failure: "FETCH_AGENTINQUIRY_LIST_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: () => {},
          failureInternalState: () => {},
        }
      )
    );
  };

  return (
    <DashboardLayout>
      <Breadcrumb title="Agent Inquiry" crumbList={crumbList} />

      {loading && <MainLoader />}
      <div className="agent--inqury--container">
        <AgentInquiryListComponent agentInquiryList={agentInquiryList} />

        <div
          style={{
            marginTop: "1rem",
            display: "flex",
            justifyContent: "flex-end",
            gap: "0.5rem",
            alignItems: "center",
          }}
        >
          <Button
            disabled={Number(agentInquiryList?.current_page) === 1}
            onClick={() =>
              fetchAgentInquiryList(Number(agentInquiryList?.current_page) - 1)
            }
          >
            <LeftOutlined />
          </Button>
          <Button className="button--modal" type="primary">
            {agentInquiryList?.current_page}
          </Button>
          <Button
            onClick={() =>
              fetchAgentInquiryList(Number(agentInquiryList?.current_page) + 1)
            }
            disabled={
              !agentInquiryList?.has_next_page ||
              agentInquiryList?.enquiries.length === 0
            }
          >
            <RightOutlined />
          </Button>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default connect(mapStateToProps)(AgentInquiry);

export const AgentInquiryListComponent = ({ agentInquiryList }) => {
  const columns = [
    {
      title: "Agent",
      align: "center",
      render: (data) => (
        <span>
          {data?.first_name} {data?.last_name}
        </span>
      ),
    },

    {
      title: "Title",
      dataIndex: "title",
      align: "center",
      render: (data) => <span>{data}</span>,
    },
    {
      title: "Message",
      dataIndex: "message",
      align: "center",
    },
    {
      title: "Email",
      align: "center",
      dataIndex: "email",
    },
    {
      title: "Inquiry by",
      align: "center",
      dataIndex: "name",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      align: "center",
      render: (phone) => {
        return phone ? phone : "NaN";
      },
    },
    {
      title: "Date",
      dataIndex: "gmt",
      align: "center",
      render: (gmt) => moment.utc(gmt).local().fromNow(),
    },
  ];

  return (
    <div className="agent--inqury--container--body">
      <Table
        bordered
        scroll={{ y: 400, x: 800 }}
        pagination={false}
        dataSource={agentInquiryList?.enquiries}
        columns={columns}
      />
    </div>
  );
};
