import { Divider, Form, Checkbox, Tag, Button } from "antd";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import UpgradeSkeletonCard from "../../../skeleton/upgradeCard/UpgradeSkeletonCard";

export default function AdPackageComponent({ setCurrent, setPlanData }) {
  const { adDurationAndPaymentType, adupgradeloading } = useSelector(
    (store) => store?.PropertyReducer
  );
  const CheckboxGroup = Checkbox.Group;
  const [recommendedTotal, setRecommendedTotal] = useState();
  const [recommendedCostList, setRecommendedCostList] = useState();
  const [popularTotal, setPopularTotal] = useState();
  const [popularCostList, setPopularCostList] = useState();
  const [bestTotal, setBestTotal] = useState();
  const [bestCostList, setBestCostList] = useState();

  useEffect(() => {
    if (adDurationAndPaymentType?.durations) {
      setRecommendedTotal(
        adDurationAndPaymentType?.durations
          ?.find((item) => item?.duration_title === "Recommended")
          ?.type?.reduce((total, itm) => total + Number(itm?.price), 0)
      );
      setPopularTotal(
        adDurationAndPaymentType?.durations
          ?.find((item) => item?.duration_title === "Most Popular")
          ?.type?.reduce((total, itm) => total + Number(itm?.price), 0)
      );
      setBestTotal(
        adDurationAndPaymentType?.durations
          ?.find((item) => item?.duration_title === "Best Value")
          ?.type?.reduce((total, itm) => total + Number(itm?.price), 0)
      );

      setRecommendedCostList(
        adDurationAndPaymentType?.durations
          ?.find((item) => item?.duration_title === "Recommended")
          ?.type?.map((itm) => itm)
      );
      setPopularCostList(
        adDurationAndPaymentType?.durations
          ?.find((item) => item?.duration_title === "Most Popular")
          ?.type?.map((itm) => itm)
      );
      setBestCostList(
        adDurationAndPaymentType?.durations
          ?.find((item) => item?.duration_title === "Best Value")
          ?.type?.map((itm) => itm)
      );
    }
  }, [adDurationAndPaymentType]);

  const handleChange = (value, type) => {
    if (type === "Recommended") {
      setRecommendedTotal(
        value.reduce((total, item) => Number(item) + total, 0)
      );
      setRecommendedCostList(
        adDurationAndPaymentType?.durations
          ?.find((item) => item?.duration_title === "Recommended")
          ?.type?.filter((itm) => value?.includes(itm?.price))
      );
    }
    if (type === "Most Popular") {
      setPopularTotal(value.reduce((total, item) => Number(item) + total, 0));
      setPopularCostList(
        adDurationAndPaymentType?.durations
          ?.find((item) => item?.duration_title === "Most Popular")
          ?.type?.filter((itm) => value?.includes(itm?.price))
      );
    }
    if (type === "Best Value") {
      setBestTotal(value.reduce((total, item) => Number(item) + total, 0));
      setBestCostList(
        adDurationAndPaymentType?.durations
          ?.find((item) => item?.duration_title === "Best Value")
          ?.type?.filter((itm) => value?.includes(itm?.price))
      );
    }
  };

  const handleSetPlanData = (value) => {
    setPlanData({
      totalAmount:
        value?.duration_title === "Recommended"
          ? recommendedTotal
          : value?.duration_title === "Most Popular"
          ? popularTotal
          : bestTotal,
      selectedPlan: value,
      featureList:
        value?.duration_title === "Recommended"
          ? recommendedCostList
          : value?.duration_title === "Most Popular"
          ? popularCostList
          : bestCostList,
    });
    setCurrent((prev) => prev + 1);
  };

  return (
    <div>
      {adupgradeloading ? (
        <div className="upgrade--card--container">
          <UpgradeSkeletonCard />
          <UpgradeSkeletonCard />
          <UpgradeSkeletonCard />
        </div>
      ) : (
        adDurationAndPaymentType?.durations?.length > 0 && (
          <Form
            className="upgrade--card--container"
            initialValues={{
              recommended: adDurationAndPaymentType?.durations
                ?.find((item) => item?.duration_title === "Recommended")
                ?.type?.map((itm) => itm?.price),
              popular: adDurationAndPaymentType?.durations
                ?.find((item) => item?.duration_title === "Most Popular")
                ?.type?.map((itm) => itm?.price),
              best_value: adDurationAndPaymentType?.durations
                ?.find((item) => item?.duration_title === "Best Value")
                ?.type?.map((itm) => itm?.price),
            }}
          >
            {adDurationAndPaymentType?.durations?.map((item) => (
              <div className="upgrade--card">
                <span
                  style={{
                    background: item?.bg_color,
                    color: item?.color,
                    width: "100%",
                  }}
                  className="upgrade--card--header"
                >
                  {" "}
                  {item?.duration_title}
                </span>
                <div className="upgrade--card--body">
                  <p>{item?.description}</p>
                  <div
                    style={{
                      color: item?.color,
                      margin: "0.5rem",
                    }}
                    className="upgrade--card--price"
                  >
                    {item?.duration_title === "Recommended"
                      ? recommendedTotal
                      : item?.duration_title === "Most Popular"
                      ? popularTotal
                      : bestTotal}
                    <span
                      style={{
                        fontSize: "1.2rem",
                        display: "flex",
                        flexDirection: "row",
                        fontWeight: 600,
                        paddingBottom: "0.5rem",
                      }}
                    >
                      {" "}
                      <span> AUD/</span>
                      <span>{item?.no_of_days} Days </span>
                    </span>
                  </div>

                  <Form.Item
                    name={
                      item?.duration_title === "Recommended"
                        ? "recommended"
                        : item?.duration_title === "Most Popular"
                        ? "popular"
                        : "best_value"
                    }
                  >
                    <CheckboxGroup
                      onChange={(value) =>
                        handleChange(value, item?.duration_title)
                      }
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "1rem",
                      }}
                      options={[
                        ...item?.type?.map((itm) => {
                          return {
                            label: (
                              <span
                                style={{
                                  display: "grid",
                                  gridTemplateColumns: "2fr 1fr",
                                  alignItems: "center",
                                  fontSize: "13px",
                                  color: "#4e5459",
                                }}
                              >
                                {itm?.description
                                  ? itm.description
                                  : itm?.cost_type?.toLowerCase() === "featured"
                                  ? `Have Your Ad Top Of The Category Listing`
                                  : itm?.cost_type?.toLowerCase() === "urgent"
                                  ? `Let People Know You Want To Sell, Rent Or Hire Quickly`
                                  : itm?.cost_type?.toLowerCase() ===
                                    "spotlight"
                                  ? `Have Your Ad Seen On The Classibazaar Home Page `
                                  : itm?.cost_type?.toLowerCase() === "logo"
                                  ? `Have Your Logo Seen  On The Classibazaar Jobs Listing Page`
                                  : ""}
                                <Tag
                                  color={
                                    itm?.cost_type?.toLowerCase() === "featured"
                                      ? "#1D5D9A98"
                                      : itm?.cost_type?.toLowerCase() ===
                                        "urgent"
                                      ? "#C5160595"
                                      : itm?.cost_type?.toLowerCase() ===
                                        "spotlight"
                                      ? "#0B666A95"
                                      : itm?.cost_type?.toLowerCase() === "logo"
                                      ? "#80000095"
                                      : ""
                                  }
                                  style={{
                                    textAlign: "center",
                                    borderRadius: "1rem",
                                  }}
                                >
                                  {itm?.cost_type}
                                </Tag>
                              </span>
                            ),
                            value: itm?.price,
                          };
                        }),
                      ]}
                    />
                  </Form.Item>

                  <Button
                    onClick={() => handleSetPlanData(item)}
                    style={{
                      background: item?.bg_color,
                      color: item?.color,
                      borderColor: item?.bg_color,
                      width: "100%",
                      margin: "0 0 0.5rem",
                    }}
                  >
                    Select Plan
                  </Button>
                </div>
              </div>
            ))}
          </Form>
        )
      )}
    </div>
  );
}
