import React from "react";
import { connect } from "react-redux";

import { Button, Card } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const mapStateToProps = (store) => {
  const PropertyReducer = store?.PropertyReducer;
  return { PropertyReducer };
};
const FeatureProperty = ({ PropertyReducer }) => {
  const { loading, realEstateData } = PropertyReducer;
  const navigate = useNavigate();
  const link = [
    { propertyStatus: "all" },
    { propertyStatus: "featured" },
    { propertyStatus: "active" },
    { propertyStatus: "inactive" },
  ];

  const handleChangePropertyType = (value) => {
    localStorage.setItem("sortValue", "");
    navigate(`/my-property/${link[value].propertyStatus}`);
  };

  if (loading) {
    return <h3>Loading...</h3>;
  }
  return (
    <div className="feature--property--container">
      <Card className="feature--card" hoverable>
        <div className="feature--card">
          <h2 className="heading--color">
            {realEstateData?.realestate_detail?.total_properties}
          </h2>
          <strong>Total No. of Property</strong>
          <Button onClick={() => handleChangePropertyType(0)}>
            View Detail
            <RightOutlined />
          </Button>
        </div>
      </Card>
      <Card className="feature--card" hoverable>
        <div className="feature--card">
          <h2 className="heading--color">
            {realEstateData?.realestate_detail?.total_featured_properties}
          </h2>
          <strong>Featured Property</strong>
          <Button onClick={() => handleChangePropertyType(1)}>
            View Detail
            <RightOutlined />
          </Button>
        </div>
      </Card>
      <Card className="feature--card" hoverable>
        <div className="feature--card">
          <h2 className="heading--color">0</h2>
          <strong>Offers / Enquiries</strong>
          <Button>
            View Detail
            <RightOutlined />
          </Button>
        </div>
      </Card>
      <Card className="feature--card" hoverable>
        <div className="feature--card">
          <h2 className="heading--color">
            {" "}
            {realEstateData?.realestate_detail?.total_properties_on_sale}
          </h2>
          <strong>Property On Sale</strong>
          <Button>
            View Detail
            <RightOutlined />
          </Button>
        </div>
      </Card>
      <Card className="feature--card" hoverable>
        <div className="feature--card">
          <h2 className="heading--color">
            {" "}
            {realEstateData?.realestate_detail?.total_properties_on_rent}{" "}
          </h2>
          <strong>Property On Rent</strong>
          <Button>
            View Detail
            <RightOutlined />
          </Button>
        </div>
      </Card>
      <Card className="feature--card" hoverable>
        <div className="feature--card">
          <h2 className="heading--color">
            {realEstateData?.realestate_detail?.sold_properties}{" "}
          </h2>
          <strong>Sold Property</strong>
          <Button>
            View Detail
            <RightOutlined />
          </Button>
        </div>
      </Card>
      <Card className="feature--card" hoverable>
        <div className="feature--card">
          <h2 className="heading--color">
            {realEstateData?.realestate_detail?.rent_properties}{" "}
          </h2>
          <strong>Rent Property</strong>
          <Button>
            View Detail
            <RightOutlined />
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default connect(mapStateToProps)(FeatureProperty);
