import { CheckOutlined } from "@ant-design/icons";
import { Button, Form, Input, InputNumber, message } from "antd";
import React, { useEffect } from "react";

export default function FlyerTemplateForm({
  setCurrent,
  setFormData,
  templateImageNo,
  formData,
  setImageList,
  imageList,
}) {
  const { TextArea } = Input;

  const handleSubmitFormData = (values) => {
    if (imageList?.length < templateImageNo) {
      message.warning(`Please Select ${templateImageNo} Image`);
    } else {
      setFormData((prev) => {
        return {
          ...prev,
          ...values,
        };
      });
      setCurrent((prev) => prev + 1);
    }
  };

  const handleSelectImage = (e, image) => {
    e.preventDefault();
    imageList?.length < templateImageNo
      ? setImageList((prev) => [...prev, image])
      : message.warning(`${templateImageNo} Image already selected`);
  };

  const handleRemoveImage = (e, image) => {
    e.preventDefault();
    setImageList((prev) => {
      return [...prev?.filter((item) => item !== image)];
    });
  };

  return (
    <Form
      onFinish={handleSubmitFormData}
      initialValues={formData}
      layout="vertical"
      className="flyer--form--container"
    >
      <div style={{ gridColumn: "span 2" }}>
        Choose Template {templateImageNo} Image
      </div>
      <div
        style={{
          gridColumn: "span 2",
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr",
          gap: "0.5rem",
          maxHeight: "23rem",
          overflowY: "scroll",
        }}
      >
        {formData?.property_image &&
          formData?.property_image?.map((image) => (
            <div className="flyer--image--card--select">
              <img
                src={image?.image_name}
                style={{
                  width: "100%",
                  height: "13rem",
                  objectFit: "cover",
                }}
              />
              {imageList?.includes(image?.image_name) ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                    gap: "1rem",
                  }}
                >
                  <span>
                    <CheckOutlined
                      style={{
                        color: "green",
                        // background: "green",
                        borderRadius: "100%",
                        padding: "0.3rem",
                        fontSize: "1.5rem",
                      }}
                    />
                  </span>

                  <button
                    className="remove--flyer--button"
                    style={{ width: "100%" }}
                    onClick={(e) => handleRemoveImage(e, image?.image_name)}
                  >
                    Remove
                  </button>
                </div>
              ) : (
                <button
                  className="default--button"
                  style={{ width: "100%" }}
                  onClick={(e) => handleSelectImage(e, image?.image_name)}
                >
                  Make as Default
                </button>
              )}
            </div>
          ))}
      </div>
      <Form.Item style={{ margin: 0 }} label="Suburb" name="suburb">
        <Input placeholder="Suburb" readOnly />
      </Form.Item>{" "}
      <Form.Item
        style={{ margin: 0 }}
        label="Property Street"
        name="property_street_name"
      >
        <Input placeholder="Property Street" readOnly />
      </Form.Item>
      <Form.Item style={{ margin: 0 }} label="Room Number" name="no_room">
        <Input placeholder="Room Number" readOnly />
      </Form.Item>
      <Form.Item
        style={{ margin: 0 }}
        label="Bathroom Number"
        name="no_of_bathroom"
      >
        <Input placeholder="Bathroom Number" readOnly />
      </Form.Item>
      <Form.Item
        style={{ margin: 0 }}
        label="Number of Car Accomodation"
        name="no_of_car_space"
      >
        <Input placeholder="Car Accomodation" readOnly />
      </Form.Item>
      <Form.Item style={{ margin: 0 }} label="Phone" name="phone">
        <InputNumber
          type="number"
          placeholder="Phone"
          style={{ width: "100%" }}
        />
      </Form.Item>
      <div style={{ gridColumn: "span 2" }}>
        <Form.Item style={{ margin: 0 }} label="Description" name="description">
          <TextArea rows={5} placeholder="description" />
        </Form.Item>
      </div>
      <div style={{ display: "flex", flexDirection: "row", gap: "0.5rem" }}>
        <Button
          style={{ width: "fit-content" }}
          onClick={() => setCurrent((prev) => prev - 1)}
          type="primary"
        >
          Back{" "}
        </Button>
        <Button
          type="primary"
          style={{
            width: "fit-content",
          }}
          htmlType="submit"
        >
          Next
        </Button>
      </div>
    </Form>
  );
}
