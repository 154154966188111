import { message } from "antd";

const INIT_STATE = {
  loading: false,
};

export const AuthReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    //reset state
    case "RESET_AUTH_STATE": {
      return {
        ...state,
        loading: false,
      };
    }

    // signin
    case "LOGIN_REQUEST_ATTEMPT": {
      return {
        ...state,
        loading: true,
      };
    }
    case "LOGIN_REQUEST_SUCCESS": {
      return {
        ...state,
        loading: false,
      };
    }
    case "LOGIN_REQUEST_FAILURE": {
      message.error(action?.payload?.data?.message);
      return {
        ...state,
        loading: false,
      };
    }

    // signup
    case "SIGNUP_REQUEST_ATTEMPT": {
      return {
        ...state,
        loading: true,
      };
    }
    case "SIGNUP_REQUEST_SUCCESS": {
      return {
        ...state,
        loading: false,
      };
    }
    case "SIGNUP_REQUEST_FAILURE": {
      message.error(action?.payload?.data?.message);
      return {
        ...state,
        loading: false,
      };
    }

    // verify-otp
    case "VERIFYOTP_REQUEST_ATTEMPT": {
      return {
        ...state,
        loading: true,
      };
    }
    case "VERIFYOTP_REQUEST_SUCCESS": {
      message.success(action?.payload?.message);
      return {
        ...state,
        loading: false,
      };
    }
    case "VERIFYOTP_REQUEST_FAILURE": {
      message.error(
        action?.payload?.data?.errors?.code ||
          action?.payload?.data?.errors?.email ||
          action?.payload?.data?.message
      );
      return {
        ...state,
        loading: false,
      };
    }

    // resend-otp
    case "RESENTOTP_REQUEST_ATTEMPT": {
      return {
        ...state,
        loading: true,
      };
    }
    case "RESENTOTP_REQUEST_SUCCESS": {
      message.success(action?.payload?.message);
      return {
        ...state,
        loading: false,
      };
    }
    case "RESENDOTP_REQUEST_FAILURE": {
      message.error(action?.payload?.data?.errors?.email);
      return {
        ...state,
        loading: false,
      };
    }

    // forgot-password-email-section
    case "FORGOTPASSWORD_OTP_REQUEST_ATTEMPT": {
      return {
        ...state,
        loading: true,
      };
    }
    case "FORGOTPASSWORD_OTP_REQUEST_SUCCESS": {
      message.success(action?.payload?.message);
      return {
        ...state,
        loading: false,
      };
    }
    case "FORGOTPASSWORD_OTP_REQUEST_FAILURE": {
      message.error(
        action?.payload?.data?.errors?.email || action?.payload?.data?.message
      );
      return {
        ...state,
        loading: false,
      };
    }

    // forgot-password-new-password-section
    case "CHANGE_PASSWORD_OTP_REQUEST_ATTEMPT": {
      return {
        ...state,
        loading: true,
      };
    }
    case "CHANGE_PASSWORD_OTP_REQUEST_SUCCESS": {
      message.success(action?.payload?.message);
      return {
        ...state,
        loading: false,
      };
    }
    case "CHANGE_PASSWORD_OTP_REQUEST_FAILURE": {
      message.error(
        action?.payload?.data?.errors?.email ||
          action?.payload?.data?.errors?.password ||
          action?.payload?.data?.message
      );
      return {
        ...state,
        loading: false,
      };
    }

    // update password
    case "UPDATE_PASSWORD_REQUEST_ATTEMPT": {
      return {
        ...state,
      };
    }

    case "UPDATE_PASSWORD_REQUEST_SUCCESS": {
      message.success(action?.payload?.message);
      return {
        ...state,
      };
    }

    case "UPDATE_PASSWORD_REQUEST_FAILURE": {
      Object.entries(action?.payload?.data?.errors)?.forEach(([key, value]) => {
        message.error(value);
      });
      return {
        ...state,
      };
    }

    default:
      return state;
  }
};
