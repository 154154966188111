import React from "react";

export default function ThreeImageLayout({ imageList }) {
  return (
    <div className="three--image--layout">
      <img
        alt=""
        src={imageList[0]}
        style={{ height: "24rem", width: "100%", objectFit: "cover" }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "0.2rem",
        }}
      >
        {imageList?.map((image, index) => {
          return (
            index > 0 && (
              <img
                alt=""
                src={image}
                style={{ height: "12rem", width: "100%", objectFit: "cover" }}
              />
            )
          );
        })}
      </div>
    </div>
  );
}
