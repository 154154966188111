import React from "react";
import { Skeleton } from "antd";

const ProfileSkeleton = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "1rem",
        flexBasis: "50%",
        padding: "1rem",
        background: "#F5F5F5",
        border: "1px solid #F1F6F9",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          justifyContent: "center",
        }}
      >
        <Skeleton.Image
          style={{ width: "8rem", height: "8rem" }}
          active={true}
        />
      </div>
      <Skeleton active />
    </div>
  );
};

export default ProfileSkeleton;
