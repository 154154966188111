import { Skeleton } from "antd";
import React from "react";

export default function UpgradeSkeletonCard() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        padding: "1rem 1.5rem ",
        borderRadius: "1rem",
        justifyContent: "center",
        alignItems: "center",
        background: "#f5f5f5",
        width: "100%",
      }}
    >
      <Skeleton.Button style={{ width: "6rem" }} active={true} />
      <Skeleton.Input
        active={true}
        style={{
          height: "1rem",
        }}
        block={true}
      />{" "}
      <Skeleton.Button
        style={{
          width: "10rem",
          margin: "0.5rem 0",
          height: "3rem",
          borderRadius: "0.5rem",
        }}
        active={true}
      />
      <Skeleton.Input
        active={true}
        style={{
          height: "1.5rem",
          borderRadius: "0.5rem",
        }}
        block={true}
      />{" "}
      <Skeleton.Input
        active={true}
        style={{
          height: "1.5rem",
          borderRadius: "0.5rem",
        }}
        block={true}
      />{" "}
      <Skeleton.Input
        active={true}
        style={{
          height: "1.5rem",
          borderRadius: "0.5rem",
        }}
        block={true}
      />{" "}
      <Skeleton.Input
        active={true}
        style={{
          height: "1.5rem",
          borderRadius: "0.5rem",
        }}
        block={true}
      />{" "}
      <Skeleton.Input
        active={true}
        style={{
          height: "2rem",
          margin: "1rem 0",
        }}
        block={true}
      />{" "}
    </div>
  );
}
