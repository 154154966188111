import { LeftOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Radio,
  message,
} from "antd";
import React, { useState } from "react";
import mastercard from "../../../assets/matercard.jpg";
import classiWallet from "../../../assets/classi-WALLET.png";
import StripeApi from "../../../component/payment/StripeApi";
import { useDispatch, useSelector } from "react-redux";
import { allActions } from "../../../Redux/myActions";
import MainLoader from "../../../component/loader/MainLoader";

export default function AdUpgradePayment({
  setCurrent,
  planData,
  postId,
  setOpenUpgradeAdDrawer,
}) {
  const userData = JSON?.parse(localStorage?.getItem("realestatedata"));
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [value, setValue] = useState(1);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { adupgradeloading } = useSelector((store) => store?.PropertyReducer);
  const { profileData } = useSelector((store) => store?.ProfileReducer);

  const onChange = (e) => {
    setValue((prev) => e.target.value);
  };

  const handleSubmitPayment = async (values) => {
    const featured = planData?.featureList?.find(
      (item) => item?.cost_type === "Featured"
    )
      ? 1
      : 0;
    const urgent = planData?.featureList?.find(
      (item) => item?.cost_type === "Urgent"
    )
      ? 1
      : 0;
    const spot = planData?.featureList?.find(
      (item) => item?.cost_type === "Spotlight"
    )
      ? 1
      : 0;
    const logo = planData?.featureList?.find(
      (item) => item?.cost_type === "Logo"
    )
      ? 1
      : 0;
    const newDate = new Date(values?.expiry_date);
    const year = newDate?.getFullYear();
    const month = newDate?.getMonth();
    if (value === 1) {
      setPaymentLoading(true);
      try {
        const stripeResponse = await StripeApi?.tokens({
          card: {
            number: values?.card_number,
            year: year,
            month: Number(month) < 10 ? `0${month}` : month,
            cvc: values?.cvc,
          },
        });

        if (!stripeResponse?.id) {
          throw new Error(stripeResponse);
        }

        const customerResposne = await StripeApi?.customers({
          id: stripeResponse?.id,
          email: userData?.user_email,
        });

        if (!customerResposne?.default_source) {
          throw new Error(customerResposne);
        }

        const chargeResponse = await StripeApi?.charges({
          postData: {
            amount: planData?.totalAmount,
          },
          orderId: {
            first_name: userData?.first_name,
            last_name: userData?.last_name,
            email: userData?.user_email,
          },
          customers: customerResposne?.id,
        });

        if (!chargeResponse?.data?.id) {
          throw new Error(chargeResponse);
        }
        setPaymentLoading(false);
        chargeResponse.status === 200 &&
          dispatch(
            allActions(
              {
                amount: planData?.totalAmount,
                paymethod: "Stripe",
                transaction_id: chargeResponse?.data?.id,
                duration_id: planData?.selectedPlan?.duration_id,
                post_id: postId,
                featured: featured,
                urgent: urgent,
                spotlight: spot,
                logo: logo,
              },
              {
                method: "post",
                endPoint: "payment",
                attempt: "POST_UPGRADE_PROPERTY_CREDIT_DETAILS_ATTEMPT",
                success: "POST_UPGRADE_PROPERTY_CREDIT_DETAILS_SUCCESS",
                failure: "POST_UPGRADE_PROPERTY_CREDIT_DETAILS_FAILURE",

                saveBearerToken: false,
                successInternalState: () => {
                  form.resetFields();
                  setTimeout(() => {
                    setOpenUpgradeAdDrawer(false);
                  }, 1000);
                },
                failureInternalState: () => {},
              }
            )
          );
      } catch (error) {
        setPaymentLoading(false);
        message.error(error?.response?.data?.error?.code);
      }
    } else {
      dispatch(
        allActions(
          {
            amount: planData?.totalAmount,
            paymethod: "classi_credit",
            transaction_id: "classi_credit",
            duration_id: planData?.selectedPlan?.duration_id,
            post_id: postId,
            featured: featured,
            urgent: urgent,
            spotlight: spot,
            logo: logo,
          },
          {
            method: "post",
            endPoint: "payment",
            attempt: "POST_UPGRADE_PROPERTY_CREDIT_DETAILS_ATTEMPT",
            success: "POST_UPGRADE_PROPERTY_CREDIT_DETAILS_SUCCESS",
            failure: "POST_UPGRADE_PROPERTY_CREDIT_DETAILS_FAILURE",

            saveBearerToken: false,
            successInternalState: () => {
              form.resetFields();
              setTimeout(() => {
                setOpenUpgradeAdDrawer(false);
              }, 1000);
            },
            failureInternalState: () => {},
          }
        )
      );
    }
  };

  return (
    <div className="adupgrade--payment--container">
      {(adupgradeloading || paymentLoading) && <MainLoader />}
      <div className="adupgrade--payment--left">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "1rem",
            alignItems: "flex-start",
          }}
        >
          <LeftOutlined
            onClick={() => setCurrent((prev) => prev - 1)}
            className="back--button"
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
              width: "100%",
              fontSize: "1rem",
              color: "slategrey",
            }}
          >
            <span
              style={{
                fontSize: "1.5rem",
                color: "slategrey",
              }}
            >
              Selected Plan
            </span>
            <Divider style={{ margin: "0" }} />
            <span>{planData?.selectedPlan?.no_of_days} Days Plan</span>
            <Divider style={{ margin: "0" }} />
            {planData?.featureList?.map((item) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "1rem",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span>{item?.cost_type}</span>
                <span>{item?.price} AUD</span>
              </div>
            ))}
            <Divider style={{ margin: "0" }} />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
                justifyContent: "space-between",
                alignItems: "center",
                fontWeight: 600,
              }}
            >
              <span>Total</span>
              <span>{planData?.totalAmount} AUD</span>
            </div>
            <Divider style={{ margin: "0" }} />
            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse",
                gap: "1rem",
                justifyContent: "space-between",
                alignItems: "center",
                fontWeight: 600,
              }}
            >
              <span>{planData?.totalAmount * 10} Classi Coin</span>
            </div>
            <Divider style={{ margin: "0" }} />
          </div>
        </div>
      </div>
      <div className="adupgrade--payment--right">
        <span
          style={{
            fontSize: "1.5rem",
            color: "slategrey",
          }}
        >
          Payment Method
        </span>
        <Divider style={{ margin: "0" }} />
        <Form
          onFinish={handleSubmitPayment}
          layout="vertical"
          initialValues={{
            payment_type: 1,
          }}
          form={form}
          style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
        >
          <Form.Item
            rules={[
              {
                required: true,
                message: "Select payment method",
              },
            ]}
            name="payment_type"
          >
            <Radio.Group
              onChange={onChange}
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
                marginTop: "0.5rem",
              }}
            >
              <div
                className={
                  value === 1
                    ? "active--payment--method--card"
                    : "payment--method--card"
                }
                onClick={() => {
                  setValue(1);
                  form.setFieldsValue({ payment_type: 1 });
                }}
              >
                <img
                  src={mastercard}
                  alt=""
                  style={{
                    width: "6rem",
                    height: "3rem",
                    objectFit: "contain",
                    background: "#e4e4e4",
                  }}
                />
                <Radio value={1}></Radio>
              </div>
              <div
                className={
                  value === 2
                    ? "active--payment--method--card"
                    : "payment--method--card"
                }
                onClick={() => {
                  setValue(2);
                  form.setFieldsValue({ payment_type: 2 });
                }}
              >
                <img
                  src={classiWallet}
                  alt=""
                  style={{
                    width: "6rem",
                    height: "3rem",
                    objectFit: "contain",
                    background: "#40b866",
                  }}
                />
                <Radio value={2}></Radio>
              </div>
            </Radio.Group>
            <span style={{ color: "black" }}>
              Your available Classi Coin: {profileData?.classi_credit}
            </span>
          </Form.Item>

          {value === 1 && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Card Name is required",
                  },
                ]}
                label="Card Name"
                name="card_name"
              >
                <Input placeholder="Card Name" />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Card Number is required",
                  },
                ]}
                label="Card Number"
                name="card_number"
              >
                <InputNumber
                  style={{ width: "100%" }}
                  placeholder="e.g 4242 4242 4242 4242"
                />
              </Form.Item>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "CVC number is required",
                    },
                  ]}
                  style={{ flexBasis: "50%", width: "50%" }}
                  label="CVC"
                  name="cvc"
                >
                  <InputNumber
                    type="number"
                    style={{ width: "50%" }}
                    placeholder="e.g 123"
                  />
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Expiry Date is required",
                    },
                  ]}
                  style={{ flexBasis: "50%", width: "50%" }}
                  label="Expiry Date"
                  name="expiry_date"
                >
                  <DatePicker
                    placeholder="MM-YYYY"
                    picker="month"
                    placement="topRight"
                  />
                </Form.Item>
              </div>
            </div>
          )}
          <Button type="primary" htmlType="submit">
            Make Payment
          </Button>
        </Form>
      </div>
    </div>
  );
}
