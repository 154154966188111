import axios from "axios";

class StripeApi {
  async tokens(postData) {
    const { number, month, year, cvc } = postData?.card;
    try {
      const res = await axios.post(
        "https://api.stripe.com/v1/tokens",
        "card[number]=" +
          encodeURIComponent(number) +
          "&" +
          "card[exp_month]=" +
          encodeURIComponent(month) +
          "&" +
          "card[exp_year]=" +
          encodeURIComponent(year) +
          "&" +
          "card[cvc]=" +
          encodeURIComponent(cvc),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization:
              "Bearer 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",
          },
        }
      );

      return res?.data;
    } catch (err) {
      throw err;
    }
  }
  async customers({ id, email }) {
    try {
      const res = await axios.post(
        "https://api.stripe.com/v1/customers",
        "source=" +
          encodeURIComponent(id) +
          "&" +
          "email=" +
          encodeURIComponent(email),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization:
              "Bearer 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",
          },
        }
      );
      return res?.data;
    } catch (err) {
      throw err;
    }
  }
  async charges({ id, postData, orderId, customers = null }) {
    try {
      // converting dollor into cent
      const AMT = Number(postData?.amount) * 100;
      const data1 =
        "amount=" +
        encodeURIComponent(Number(AMT)) +
        "&" +
        "currency=" +
        encodeURIComponent("AUD") +
        "&" +
        "description=" +
        encodeURIComponent(
          "Charge from inquriy Credit from classiEstateAgency App. Name: " +
            orderId.first_name +
            " " +
            orderId.last_name +
            " email:" +
            orderId.email +
            " token customers: " +
            customers
        ) +
        "&" +
        "statement_descriptor=" +
        encodeURIComponent("CHR_CLASSIDEALS") +
        "&" +
        "customer=" +
        encodeURIComponent(customers);
      const res = await axios.post("https://api.stripe.com/v1/charges", data1, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization:
            "Bearer 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",
        },
      });
      return res;
    } catch (err) {
      throw err;
    }
  }
}

export default new StripeApi();
