import { Button, Form, Modal, Radio } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { allActions } from "../../Redux/myActions";
import { useState } from "react";
import MainLoader from "../loader/MainLoader";

const UpdateAdStatus = ({
  openAdStatus,
  setOpenAdStatus,
  postData,
  setPostData,
  fetchPropertyList,
  propertyType,
}) => {
  const ad_status = [
    { label: "Draft", value: "0" },
    { label: "Active", value: "1" },
    { label: "Off Martket", value: "2" },
    { label: "Withdrawn", value: "3" },
    {
      label: propertyType === "Buy" ? "Sold" : "Leased",
      value: "4",
    },
    { label: "Under Offer", value: "5" },
  ];
  const dispatch = useDispatch();
  const handleOk = () => {
    setOpenAdStatus(false);
  };
  const [isLoading, setLoading] = useState(false);

  const handleCloseModal = () => {
    setPostData("");
    setOpenAdStatus(false);
  };

  const handleUpdateAdStatus = (values) => {
    setLoading(true);
    const ad_id = ad_status?.find((item) => item?.label === values?.ad_status);
    dispatch(
      allActions(
        {},
        {
          method: "get",
          endPoint: `change_ad_status?status=${ad_id?.value}&postid=${postData?.post_id}`,
          attempt: "UPDATE_AD_STATUS_REQUEST_ATTEMPT",
          success: "UPDATE_AD_STATUS_REQUEST_SUCCESS",
          failure: "UPDATE_AD_STATUS_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: () => {
            setLoading(false);
            setTimeout(() => {
              fetchPropertyList();
              setOpenAdStatus(false);
            }, 500);
          },
          failureInternalState: () => {
            setLoading(false);
          },
        }
      )
    );
  };

  return (
    <Modal
      title="Update Ad Status"
      open={openAdStatus}
      onOk={handleOk}
      onCancel={handleCloseModal}
      footer={null}
    >
      {isLoading && <MainLoader />}
      <Form
        initialValues={{ ad_status: postData?.statusname }}
        layout="vertical"
        onFinish={handleUpdateAdStatus}
      >
        <Form.Item name="ad_status" label="Select Ad Status">
          <Radio.Group
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "1rem",
            }}
            options={ad_status?.map((item) => {
              return {
                label: item?.label,
                value: item?.label,
              };
            })}
          />
        </Form.Item>
        <div
          style={{
            display: "flex",
            marginTop: "1rem",
            justifyContent: "flex-end",
            gap: "1rem",
            width: "100%",
          }}
        >
          <Button htmlType="submit">Update</Button>
          <Button onClick={handleCloseModal} danger>
            Cancel
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default UpdateAdStatus;
