import React from "react";
import ConcentricCircle from "../skeletonComponent/ConcentricCircle";
import SmallCircle from "../skeletonComponent/SmallCircle";
import BluntEdgeSkeleton from "../skeletonComponent/BluntEdgeSkeleton";

const PieChartSkeleton = () => {
  return (
    <div className="pie--chart--group--container">
      <div className="pie--chart--container">
        <ConcentricCircle />
        <div
          style={{ display: "flex", flexDirection: "column", gap: "0.7rem" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "0.5rem",
              alignItems: "center",
            }}
          >
            <SmallCircle />
            <BluntEdgeSkeleton />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "0.5rem",
              alignItems: "center",
            }}
          >
            <SmallCircle />
            <BluntEdgeSkeleton />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "0.5rem",
              alignItems: "center",
            }}
          >
            <SmallCircle />
            <BluntEdgeSkeleton />
          </div>
        </div>
      </div>
      <div className="pie--chart--container">
        <ConcentricCircle />
        <div
          style={{ display: "flex", flexDirection: "column", gap: "0.7rem" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "0.5rem",
              alignItems: "center",
            }}
          >
            <SmallCircle />
            <BluntEdgeSkeleton />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "0.5rem",
              alignItems: "center",
            }}
          >
            <SmallCircle />
            <BluntEdgeSkeleton />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "0.5rem",
              alignItems: "center",
            }}
          >
            <SmallCircle />
            <BluntEdgeSkeleton />
          </div>
        </div>
      </div>
      <div className="pie--chart--container">
        <ConcentricCircle />
        <div
          style={{ display: "flex", flexDirection: "column", gap: "0.7rem" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "0.5rem",
              alignItems: "center",
            }}
          >
            <SmallCircle />
            <BluntEdgeSkeleton />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "0.5rem",
              alignItems: "center",
            }}
          >
            <SmallCircle />
            <BluntEdgeSkeleton />
          </div>
        </div>
      </div>
      <div className="pie--chart--container">
        <ConcentricCircle />
        <div
          style={{ display: "flex", flexDirection: "column", gap: "0.7rem" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "0.5rem",
              alignItems: "center",
            }}
          >
            <SmallCircle />
            <BluntEdgeSkeleton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PieChartSkeleton;
