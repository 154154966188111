import { Drawer, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allActions } from "../../../Redux/myActions";
import { pdfjs } from "react-pdf";
import { AiOutlineEye } from "react-icons/ai";
import FlyerPdf from "./FlyerPdf";

export default function FlyerVideoModal({
  flyerVideoDrawer,
  setFlyerVideoDrawer,
  postId,
}) {
  const dispatch = useDispatch();
  const { flyerList } = useSelector((store) => store?.PropertyReducer);
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [pdf, setPdf] = useState("");
  const [active, setActive] = useState(false);

  useEffect(() => {
    dispatch(
      allActions(
        {},
        {
          method: "get",
          endPoint: `flyers/${postId}`,
          attempt: "FETCH_FLYERLIST_REQUEST_ATTEMPT",
          success: "FETCH_FLYERLIST_REQUEST_SUCCESS",
          failure: "FETCH_FLYERLIST_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: () => {},
          failureInternalState: () => {},
        }
      )
    );
  }, []);

  const onClose = () => {
    setFlyerVideoDrawer(false);
  };

  const columns = [
    {
      title: "Pdf Url",
      dataIndex: "url",
      align: "center",
    },
    {
      title: "Action",
      align: "center",
      width: "200",
      render: (data) => (
        <AiOutlineEye
          onClick={() => {
            setPdf(data?.url);
            setActive(true);
          }}
          style={{ fontSize: "1.5rem", cursor: "pointer", color: "blue" }}
        />
      ),
    },
  ];

  return (
    <Drawer
      open={flyerVideoDrawer}
      title="Flyer & Video Template List"
      placement="right"
      onClose={onClose}
      width="1000"
    >
      <Table
        pagination={false}
        scroll={{ y: 400 }}
        dataSource={flyerList}
        columns={columns}
      />
      {active && (
        <FlyerPdf
          active={active}
          setPdf={setPdf}
          setActive={setActive}
          pdf={pdf}
        />
      )}
    </Drawer>
  );
}
