import React from "react";
import { Skeleton } from "antd";

const InviteLinkSkeleton = () => {
  return (
    <div
      // style={{
      //   display: "flex",
      //   flexDirection: "row",
      //   gap: "1rem",
      //   padding: "1.5rem",
      // }}
      className="referral--container"
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "1rem",
          flexBasis: "40%",
          border: "1px solid #F1F6F9",
          background: "white",
          borderRadius: "0.5rem",
          padding: "2rem",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            justifyContent: "center",
          }}
        >
          <Skeleton.Image
            style={{ width: "8rem", height: "8rem" }}
            active={true}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "0.5rem",
            alignItems: "center",
            marginTop: "1rem",
            width: "100%",
          }}
        >
          <Skeleton.Input block={true} style={{ borderRadius: "3rem" }} />
          <Skeleton.Button active={true} shape={"default"} block={false} />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flexBasis: "60%",
          alignItems: "center",
          gap: "1rem",
          padding: "2rem",
          border: "1px solid #F1F6F9",
          background: "white",
          borderRadius: "0.5rem",
        }}
      >
        <Skeleton ctive={true} active size="large" />{" "}
        <Skeleton.Button
          style={{ marginTop: "1.5rem" }}
          active={true}
          block={true}
        />
      </div>
    </div>
  );
};

export default InviteLinkSkeleton;
