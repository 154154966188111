import React from "react";
import { Skeleton, Table } from "antd";

const TableSkeleton = () => {
  const columns = [
    {
      title: (
        <Skeleton.Button
          active={true}
          size="default"
          shape="default"
          block={true}
        />
      ),
    },
    {
      title: (
        <Skeleton.Button
          active={true}
          size="default"
          shape="default"
          block={true}
        />
      ),
    },
    {
      title: (
        <Skeleton.Button
          active={true}
          size="default"
          shape="default"
          block={true}
        />
      ),
    },
    {
      title: (
        <Skeleton.Button
          active={true}
          size="default"
          shape="default"
          block={true}
        />
      ),
    },
    {
      title: (
        <Skeleton.Button
          active={true}
          size="default"
          shape="default"
          block={true}
        />
      ),
    },
    {
      title: (
        <Skeleton.Button
          active={true}
          size="default"
          shape="default"
          block={true}
        />
      ),
    },
  ];

  const data = [
    <Skeleton.Button active={true} size="small" shape="default" block={true} />,
    <Skeleton.Button active={true} size="small" shape="default" block={true} />,
    <Skeleton.Button active={true} size="small" shape="default" block={true} />,
    <Skeleton.Button active={true} size="small" shape="default" block={true} />,
    <Skeleton.Button active={true} size="small" shape="default" block={true} />,
  ];
  return (
    <div>
      <Table pagination={false} columns={columns} dataSource={data} />
    </div>
  );
};

export default TableSkeleton;
