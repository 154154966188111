import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { allActions } from "../Redux/myActions";

export default function useOfferMessageHook(
  pageNumber,
  setOfferMessage,
  fetctOfferUserList,
  setOfferProperty,
  value
) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      allActions(
        {},
        {
          method: "get",
          endPoint: `offermessages_new?page=${pageNumber}`,
          attempt: "FETCH_OFFER_MESSAGE_REQUEST_ATTEMPT",
          success: "FETCH_OFFER_MESSAGE_REQUEST_SUCCESS",
          failure: "FETCH_OFFER_MESSAGE_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: (res) => {
            if (value) {
              res?.offers?.length > 0 && fetctOfferUserList(value);
              res?.offers?.length > 0 &&
                setOfferProperty(
                  res?.offers?.find(
                    (item) => Number(item?.post_id) === Number(value)
                  )
                );
            } else {
              res?.offers?.length > 0 &&
                fetctOfferUserList(res?.offers[0]?.post_id);
              res?.offers?.length > 0 && setOfferProperty(res?.offers[0]);
            }

            setOfferMessage((prev) => [...prev, ...res?.offers]);
          },
          failureInternalState: () => {},
        }
      )
    );
  }, [pageNumber]);

  return null;
}
