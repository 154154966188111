import {
  Drawer,
  Input,
  Form,
  InputNumber,
  Select,
  message,
  Button,
} from "antd";
import { useRef, useState } from "react";
import { UserOutlined } from "@ant-design/icons";
import { useDispatch, connect } from "react-redux";

import ScreenSize from "../screenSizeReader/ScreenSize";
import { allActions } from "../../Redux/myActions";
import { validateInputNumber } from "../../Helpers/FrontendHelper";

const mapStateToProps = (store) => {
  const AgentAgencyReducer = store?.AgentAgencyReducer;
  return { AgentAgencyReducer };
};

const AddAgentDrawer = ({
  agentDrawer,
  setAgentDrawer,
  AgentAgencyReducer,
  handleFetchAgent,
}) => {
  const imageRef = useRef();
  const dispatch = useDispatch();
  const drawerWidth = ScreenSize({ width: "75%" });
  const [form] = Form.useForm();

  const { agentloading, agentimageloading, agentImage } = AgentAgencyReducer;

  const onClose = () => {
    setAgentDrawer(false);
    form.resetFields();
  };

  const handleSubmitNewAgent = (values) => {
    dispatch(
      allActions(
        {
          first_name: values?.first_name,
          last_name: values?.last_name,
          email: values?.email,
          fax: values?.fax ? values?.fax : "",
          mobile: values?.mobile_number,
          phone: values?.phone_number,
          agent_status: values?.agent_status,
          recive_email: "No",
          image_url: agentImage[0]?.image_name ? agentImage[0]?.image_name : "",
          thumbnail: agentImage[0]?.image_thumnail_name
            ? agentImage[0]?.image_thumnail_name
            : "",
          agent_color: values?.agent_color,
        },
        {
          method: "post",
          endPoint: "agent/add_agent",
          attempt: "ADD_AGENT_REQUEST_ATTEMPT",
          success: "ADD_AGENT_REQUEST_SUCCESS",
          failure: "ADD_AGENT_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: () => {
            setTimeout(() => {
              form.resetFields();
              setAgentDrawer(false);
              handleFetchAgent();
            }, 1000);
          },
          failureInternalState: () => {},
        }
      )
    );
  };

  const handleClickImage = (e) => {
    imageRef.current.click();
  };

  const handleFileSelected = (event) => {
    const form = new FormData();
    form.append("files[]", event.target.files[0]);

    dispatch(
      allActions(form, {
        method: "post",
        endPoint: "upload_post_image",
        attempt: "UPLOAD_AGENT_IMAGE_REQUEST_ATTEMPT",
        success: "UPLOAD_AGENT_IMAGE_REQUEST_SUCCESS",
        failure: "UPLOAD_AGENT_IMAGE_REQUEST_FAILURE",

        saveBearerToken: false,
        multipartFormData: true,
        successInternalState: () => {},
        failureInternalState: () => {},
      })
    );
  };

  return (
    <Drawer
      title="Add New Agent"
      placement="right"
      onClose={onClose}
      open={agentDrawer}
      width={drawerWidth}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmitNewAgent}
        labelAlign="left"
        className="add--agent--form--container"
        initialValues={{
          agent_status: "active",
        }}
      >
        <div className="add--agent--container">
          <Form.Item
            className="agent--child--container"
            label="First Name"
            name="first_name"
            rules={[
              {
                required: true,
                message: "First Name is required",
              },
            ]}
          >
            <Input placeholder="Enter First Name" />
          </Form.Item>
          <Form.Item
            className="agent--child--container"
            label="Last Name"
            name="last_name"
            rules={[
              {
                required: true,
                message: "Last Name is required",
              },
            ]}
          >
            <Input placeholder="Enter Last Name" />
          </Form.Item>
        </div>
        <div className="add--agent--container">
          <Form.Item
            className="agent--child--container"
            name="email"
            label="Email"
            labelAlign="left"
            rules={[
              {
                required: true,
                message: "Email is required",
              },
            ]}
          >
            <Input type="email" placeholder="Enter Email" />
          </Form.Item>
          <Form.Item
            className="agent--child--container"
            label="Status"
            name="agent_status"
            labelAlign="left"
            rules={[
              {
                required: true,
                message: "Agent Status is required",
              },
            ]}
          >
            <Select
              placeholder="Select status"
              options={[
                {
                  label: "Active",
                  value: "active",
                },
                {
                  label: "Inactive",
                  value: "inactive",
                },
              ]}
            />
          </Form.Item>
        </div>
        <div className="add--agent--container">
          <Form.Item
            className="agent--child--container"
            label="Phone Number"
            name="phone_number"
            rules={[
              {
                required: true,
                message: "Phone Number is required",
              },
              { validator: validateInputNumber },
            ]}
          >
            <InputNumber
              type="number"
              style={{ width: "100%" }}
              placeholder="e.g 9712353412"
            />
          </Form.Item>
          <Form.Item
            className="agent--child--container"
            label="Mobile Number"
            name="mobile_number"
            rules={[
              {
                required: true,
                message: "Mobile number is required",
              },
              { validator: validateInputNumber },
            ]}
          >
            <InputNumber
              type="number"
              style={{ width: "100%" }}
              placeholder="Enter mobile number"
            />
          </Form.Item>
        </div>
        <div className="add--agent--container">
          {/* <Form.Item
            className="agent--child--container"
            label="Agent Color"
            name="agent_color"
          >
            <Input style={{ width: "100%" }} placeholder="Enter Agent Color" />
          </Form.Item> */}

          <Form.Item
            className="agent--child--container"
            label="Upload Image"
            labelAlign="left"
            style={{
              position: "relative",
            }}
          >
            <input
              style={{ display: "none" }}
              ref={imageRef}
              type="file"
              accept=".png, .jpg, .jpeg"
              onChange={handleFileSelected}
            />
            {agentImage?.length > 0 ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "0.5rem",
                  alignItems: "flex-end",
                }}
              >
                <img
                  src={agentImage[0]?.image_name}
                  alt="img"
                  style={{
                    width: "5rem",
                    height: "5rem",
                    borderRadius: "0.5rem",
                    border: "1px solid gray",
                  }}
                />
                <Button
                  type="primary"
                  className="upload--button"
                  onClick={handleClickImage}
                ></Button>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "0.2rem",
                  alignItems: "end",
                }}
              >
                <UserOutlined
                  style={{
                    fontSize: "5rem",
                    borderRadius: "0.5rem",
                    border: "1px solid gray",
                    color: "gray",
                  }}
                />
                <Button
                  type="primary"
                  className="upload--button"
                  onClick={handleClickImage}
                ></Button>
              </div>
            )}
          </Form.Item>
          <Form.Item className="agent--child--container" label="FAX" name="fax">
            <InputNumber style={{ width: "100%" }} placeholder="Enter FAX" />
          </Form.Item>
        </div>

        <Form.Item>
          <Button
            className="button--modal"
            loading={agentloading}
            type="primary"
            htmlType="submit"
          >
            {agentloading ? "Loading..." : "Add Agent"}{" "}
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default connect(mapStateToProps)(AddAgentDrawer);
