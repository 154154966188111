import { Button, Modal } from "antd";
import React, { useState } from "react";

export default function FlyerTemplateOptionModal({
  setCurrent,
  setTemplateImageNo,
  templateImageNo,
}) {
  return (
    <div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr 1fr",
          gap: "1rem",
          margin: "1rem 0",
        }}
      >
        <div
          className={
            templateImageNo === 1
              ? "active-video-template-card"
              : "video-template-card"
          }
          onClick={() => setTemplateImageNo(1)}
        >
          <span className="flyer--title">Single Image Template</span>
        </div>
        <div
          className={
            templateImageNo === 2
              ? "active-video-template-card"
              : "video-template-card"
          }
          onClick={() => setTemplateImageNo(2)}
        >
          <span className="flyer--title">2 Images Template</span>
        </div>
        <div
          className={
            templateImageNo === 3
              ? "active-video-template-card"
              : "video-template-card"
          }
          onClick={() => setTemplateImageNo(3)}
        >
          <span className="flyer--title">3 Images Template</span>
        </div>
        <div
          className={
            templateImageNo === 4
              ? "active-video-template-card"
              : "video-template-card"
          }
          onClick={() => setTemplateImageNo(4)}
        >
          <span className="flyer--title">4 Images Template</span>
        </div>
      </div>

      <Button onClick={() => setCurrent((prev) => prev + 1)} type="primary">
        Next{" "}
      </Button>
    </div>
  );
}
