import React from "react";

const customStyle = {
  "--i": 1,
};

const customStyle2 = {
  "--i": 2,
};

const customStyle3 = {
  "--i": 3,
};

const customStyle4 = {
  "--i": 4,
};
const customStyle5 = {
  "--i": 5,
};
const customStyle6 = {
  "--i": 6,
};
const customStyle7 = {
  "--i": 7,
};
const customStyle8 = {
  "--i": 8,
};
const customStyle9 = {
  "--i": 9,
};
const customStyle10 = {
  "--i": 10,
};

const MainLoader = () => {
  return (
    <div className="loader-container">
      <div className="loader--design">
        <span style={customStyle}></span>
        <span style={customStyle2}></span>
        <span style={customStyle3}></span>
        <span style={customStyle4}></span>
        <span style={customStyle5}></span>
        <span style={customStyle6}></span>
        <span style={customStyle7}></span>
        <span style={customStyle8}></span>
        <span style={customStyle9}></span>
        <span style={customStyle10}></span>
      </div>
    </div>
  );
};

export default MainLoader;
