import React from "react";

export default function FourImageLayout({ imageList }) {
  return (
    <div className="four--image--layout">
      <img
        alt=""
        src={imageList[0]}
        style={{
          height: "24rem",
          width: "100%",
          objectFit: "cover",
          gridColumn: "span 3",
        }}
      />

      {imageList?.map((image, index) => {
        return (
          index > 0 && (
            <img
              alt=""
              src={image}
              style={{ height: "12rem", width: "100%", objectFit: "cover" }}
            />
          )
        );
      })}
    </div>
  );
}
