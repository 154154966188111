// import axios from "axios";

import { axiosApi1 } from "./apiConfig";
import { getCookie, setCookie } from "./FrontendHelper";

const HeaderData = async (update = false, isToken = true) => {
  const token = getCookie("accessToken");

  if (token === null) {
    return {
      "Content-Type": "application/json",
    };
  } else if (!isToken) {
    return {
      "Content-Type": "application/json",
    };
  } else if (update) {
    return {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + token,
    };
  } else {
    return {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };
  }
};

// axios.defaults.baseURL = "https://classibazaar.com/api/v1/";

export const Api1 = async (action) => {
  let response = null;
  if (
    action.type_data.method === "POST" ||
    action.type_data.method === "post"
  ) {
    response = await axiosApi1.post(action.type_data.endPoint, action.payload, {
      headers: await HeaderData(action.type_data.multipartFormData),
    });
  } else if (
    action.type_data.method === "PUT" ||
    action.type_data.method === "put"
  ) {
    response = await axiosApi1.put(action.type_data.endPoint, action.payload, {
      headers: await HeaderData(action.type_data.multipartFormData),
    });
  } else if (
    action.type_data.method === "PATCH" ||
    action.type_data.method === "patch"
  ) {
    response = await axiosApi1.patch(
      action.type_data.endPoint,
      action.payload,
      {
        headers: await HeaderData(action.type_data.multipartFormData),
      }
    );
  } else if (
    action.type_data.method === "DELETE" ||
    action.type_data.method === "delete"
  ) {
    response = await axiosApi1.delete(action.type_data.endPoint, {
      params: { ...action.type_data.params },
      data: { ...action.type_data.params },
      headers: await HeaderData(action.type_data.multipartFormData),
    });
  } else {
    response = await axiosApi1.get(action.type_data.endPoint, {
      params: { ...action.type_data.params },
      headers: await HeaderData(
        action.type_data.multipartFormData,
        action?.type_data?.isToken
      ),
    });
  }

  if (action.type_data.saveBearerToken) {
    if (response?.data?.accessToken !== undefined) {
      setCookie("accessToken", response.data.accessToken);
    } else if (response.data.toke !== undefined) {
      setCookie("accessToken", response.data.token);
    } else if (response.data.access_token !== undefined) {
      setCookie("accessToken", response.data.access_token);
    }
  }
  return response;
};
