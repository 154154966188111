import React, { useEffect, useState } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Modal, Radio } from "antd";

import { allActions } from "../../Redux/myActions";
import { useDispatch, connect } from "react-redux";
import MainLoader from "../loader/MainLoader";

const mapStateToProps = (store) => {
  const { PropertyReducer } = store;
  return { PropertyReducer };
};

const DeletePostModal = ({
  isDeletePost,
  setIsDeletePost,
  PropertyReducer,
  postId,
  setPostId,
  fetchPropertyList,
}) => {
  const dispatch = useDispatch();
  const CheckboxGroup = Checkbox.Group;
  const RadioGroup = Radio.Group;
  const { addeleteloading, adDeleteReasonList, deletepostloading } =
    PropertyReducer;
  const handleOk = () => {
    setIsDeletePost(false);
    setPostId("");
  };

  const handleCancel = () => {
    setIsDeletePost(false);
    setPostId("");
  };

  useEffect(() => {
    dispatch(
      allActions(
        {},
        {
          method: "get",
          endPoint: "delete_reasons",
          attempt: "FETCH_POST_DELETER_REASON_REQUEST_ATTEMPT",
          success: "FETCH_POST_DELETER_REASON_REQUEST_SUCCESS",
          failure: "FETCH_POST_DELETER_REASON_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: () => {},
          failureInternalState: () => {},
        }
      )
    );
  }, []);

  const handleDeletePost = (values) => {
    dispatch(
      allActions(
        {
          reason: values?.reason,
          postid: postId,
        },
        {
          method: "post",
          endPoint: "deletepost",
          attempt: "DELETE_POST_REQUEST_ATTEMPT",
          success: "DELETE_POST_REQUEST_SUCCESS",
          failure: "DELETE_POST_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: () => {
            setTimeout(() => {
              setPostId("");
              setIsDeletePost(false);
              fetchPropertyList();
            }, 1000);
          },
          failureInternalState: () => {},
        }
      )
    );
  };

  return (
    <Modal
      title="Why Are You Deleting an Ad?"
      open={isDeletePost}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={false}
    >
      {deletepostloading && <MainLoader />}
      {addeleteloading ? (
        <MainLoader />
      ) : (
        <Form onFinish={handleDeletePost}>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Please select an Ad delete reason",
              },
            ]}
            name="reason"
          >
            <RadioGroup
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                margin: "1.5rem 0",
              }}
              options={[
                ...adDeleteReasonList?.map((reason) => {
                  return {
                    label: reason,
                    value: reason,
                  };
                }),
              ]}
              className="two-column-checkbox"
            />
          </Form.Item>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              gap: "0.5rem",
            }}
          >
            <Button
              htmlType="submit"
              style={{ background: "red" }}
              className="button--modal--primary"
            >
              Delete Ad
            </Button>
            <Button
              onClick={() => {
                setPostId("");
                setIsDeletePost(false);
              }}
            >
              Cancel
            </Button>
          </div>
        </Form>
      )}
    </Modal>
  );
};

export default connect(mapStateToProps)(DeletePostModal);
