import { useDispatch, useSelector } from "react-redux";
import ScreenSize from "../../component/screenSizeReader/ScreenSize";
import {
  Button,
  Card,
  Col,
  ColorPicker,
  Divider,
  Drawer,
  Form,
  Input,
  InputNumber,
  Space,
} from "antd";
import { useEffect, useMemo, useState } from "react";
import { allActions } from "../../Redux/myActions";
import Autocomplete from "react-google-autocomplete";
import { validateInputNumber } from "../../Helpers/FrontendHelper";
import MainLoader from "../../component/loader/MainLoader";
import type { Color, ColorPickerProps } from "antd/es/color-picker";

export const CompanyDetail = ({
  profileData,
  open,
  setOpen,
  fetchProfileUpdate,
}) => {
  const dispatch = useDispatch();
  const drawerWidth = ScreenSize({ width: "75%" });
  const [form] = Form.useForm();
  const [address, setAddress] = useState("");
  const { profileloading } = useSelector((store) => store?.ProfileReducer);
  const [companyColorHex, setCompanyColorHex] = useState(
    profileData?.company_color
  );
  const [agentColorHex, setAgentColorHex] = useState(profileData?.agent_color);
  const [fontColorHex, setFontColorHex] = useState(profileData?.font_color);

  const [formatHex, setFormatHex] = useState("hex");

  const companyHexString = useMemo(
    () =>
      typeof companyColorHex === "string"
        ? companyColorHex
        : companyColorHex?.toHexString(),
    [companyColorHex]
  );

  const agentHexString = useMemo(
    () =>
      typeof agentColorHex === "string"
        ? agentColorHex
        : agentColorHex?.toHexString(),
    [agentColorHex]
  );

  const fontHexString = useMemo(
    () =>
      typeof fontColorHex === "string"
        ? fontColorHex
        : fontColorHex?.toHexString(),
    [fontColorHex]
  );

  useEffect(() => {
    profileData && setAddress(profileData.street_address);
  }, [profileData]);

  const handleUpdateProfileDetail = (values) => {
    dispatch(
      allActions(
        {
          ...values,
          user_mobile: values?.user_mobile
            ? values?.user_mobile?.toString()
            : "",
          phone: values?.phone ? values?.phone?.toString() : "",
          address: address,
          font_color: values?.font_color,
          company_color: companyHexString,
          agent_color: agentHexString,
          font_color: fontHexString,
        },
        {
          method: "post",
          endPoint: "profile_new",
          attempt: "UPDATE_PROFILE_DETAIL_REQUEST_ATTEMPT",
          success: "UPDATE_PROFILE_DETAIL_REQUEST_SUCCESS",
          failure: "UPDATE_PROFILE_DETAIL_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: () => {
            setOpen(false);
            fetchProfileUpdate();
          },
          failureInternalState: () => {},
        }
      )
    );
  };

  const handleAddAddress = (place) => {
    form.setFieldValue({ street_address: place?.formatted_address });
    setAddress(place?.formatted_address);
  };

  return (
    <Drawer
      title="Edit Profile Details"
      placement="right"
      onClose={() => setOpen(false)}
      open={open}
      width={drawerWidth}
    >
      {profileloading && <MainLoader />}
      <div style={{ margin: "0 1rem" }}>
        <Form
          initialValues={{
            email: profileData?.user_email,
            first_name: profileData?.first_name,
            last_name: profileData?.last_name,
            phone: profileData?.contact_number,
            mobile: profileData?.mobile_number,
            company_name: profileData?.company_name,
            company_color: profileData?.company_color
              ? profileData?.company_color
              : "",
            agent_color: profileData?.agent_color
              ? profileData?.agent_color
              : "",
            street_address: profileData?.street_address,
            font_color: profileData?.font_color,
          }}
          layout="vertical"
          onFinish={handleUpdateProfileDetail}
          form={form}
        >
          <div className="two--row--container">
            <Form.Item
              labelAlign="left"
              rules={[
                {
                  required: true,
                  message: "Company Name is required",
                },
              ]}
              className="two--row--child--container"
              label="Company Name"
              name="company_name"
            >
              <Input placeholder="Enter Company Name" />
            </Form.Item>
            <Form.Item
              labelAlign="left"
              className="two--row--child--container"
              label="Company Color"
              name="company_color"
            >
              <Space>
                <Col>
                  <ColorPicker
                    format={formatHex}
                    value={companyColorHex}
                    onChange={setCompanyColorHex}
                    onFormatChange={setFormatHex}
                  />
                </Col>
                <Col>
                  HEX: <span>{companyHexString}</span>
                </Col>
              </Space>
            </Form.Item>
          </div>
          <div className="two--row--container">
            <Form.Item
              labelAlign="left"
              rules={[
                {
                  required: true,
                  message: "Email is required",
                },
              ]}
              className="two--row--child--container"
              label="Email"
              value={profileData?.user_email}
              name="email"
            >
              <Input readOnly type="email" placeholder="Enter an Email" />
            </Form.Item>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
                marginBottom: "1rem",
              }}
              className="two--row--child--container"
            >
              <span>Address</span>
              <Autocomplete
                placeholder={address}
                apiKey="AIzaSyAKLUx_rnltQ2u9Xr39DcpX3UdRr293gCU"
                style={{
                  width: "100%",
                  outline: "none",
                  height: "2rem",
                  border: "1px solid #D8D9DA",
                  padding: "0 0.5rem",
                }}
                onPlaceSelected={(place) => {
                  handleAddAddress(place);
                }}
                options={{
                  types: ["address"],
                  componentRestrictions: { country: "au" },
                }}
              />
            </div>
          </div>

          <div className="two--row--container">
            <Form.Item
              labelAlign="left"
              rules={[
                {
                  required: true,
                  message: "First Name is required",
                },
              ]}
              className="two--row--child--container"
              label="First Name"
              name="first_name"
            >
              <Input placeholder="First Name" />
            </Form.Item>
            <Form.Item
              labelAlign="left"
              rules={[
                {
                  required: true,
                  message: "Last Name is required",
                },
              ]}
              className="two--row--child--container"
              label="Last Name"
              name="last_name"
            >
              <Input placeholder="Last Name" />
            </Form.Item>
          </div>
          <div className="two--row--container">
            <Form.Item
              labelAlign="left"
              rules={[
                {
                  required: true,
                  message: "Phone Number is required",
                },

                { validator: validateInputNumber },
              ]}
              className="two--row--child--container"
              label="Phone Number"
              name="phone"
            >
              <InputNumber
                type="number"
                style={{ width: "100%" }}
                placeholder="Enter Phone Number"
              />
            </Form.Item>
            <Form.Item
              labelAlign="left"
              className="two--row--child--container"
              label="Mobile Number"
              name="mobile"
              rules={[{ validator: validateInputNumber }]}
            >
              <InputNumber
                type="number"
                style={{ width: "100%" }}
                placeholder="Enter Mobile Number"
              />
            </Form.Item>
          </div>
          <div className="two--row--container">
            <Form.Item
              labelAlign="left"
              className="two--row--child--container"
              label="Agent Color"
              name="agent_color"
            >
              <Space>
                <Col>
                  <ColorPicker
                    format={formatHex}
                    value={agentColorHex}
                    onChange={setAgentColorHex}
                    onFormatChange={setFormatHex}
                  />
                </Col>
                <Col>
                  HEX: <span>{agentHexString}</span>
                </Col>
              </Space>{" "}
            </Form.Item>
            <Form.Item
              labelAlign="left"
              className="two--row--child--container"
              label="Text Color"
              name="font_color"
            >
              <Space>
                <Col>
                  <ColorPicker
                    format={formatHex}
                    value={fontColorHex}
                    onChange={setFontColorHex}
                    onFormatChange={setFormatHex}
                  />
                </Col>
                <Col>
                  HEX: <span>{fontHexString}</span>
                </Col>
              </Space>{" "}
            </Form.Item>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              width: "100%",
            }}
            className="edit--profile--button"
          >
            <Button className="button--modal" type="primary" htmlType="submit">
              Update
            </Button>
          </div>
        </Form>
      </div>
    </Drawer>
  );
};
