import React from "react";
import { Navigate } from "react-router-dom";
import { isAuth } from "./FrontendHelper";

const PrivateRoute = ({ children }) => {
  const authed = isAuth(); // isauth() returns true or false based on localStorage

  return authed ? children : <Navigate to="/" />;
};

export default PrivateRoute;
