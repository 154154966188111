import React from "react";
import { NavLink } from "react-router-dom";
import { RightOutlined } from "@ant-design/icons";

const Breadcrumb = ({ title, crumbList, isSticky }) => {
  return (
    <div className={"breadcrumb--container"}>
      <span className="breadcrumb--header">{title}</span>
      <div className="breadcrumb--inner--container">
        <div className="breadcrumb--body">
          {crumbList?.map((item, index) => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: "0.3rem",
              }}
              key={item}
            >
              {index !== 0 && <RightOutlined />}
              <NavLink
                style={{ cursor: "pointer", color: "#0b3e75ce" }}
                to={item?.link}
              >
                {item?.title}
              </NavLink>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Breadcrumb;
