import { combineReducers } from "redux";
import { combineEpics } from "redux-observable";

import { AllEpic } from "./myEpics";
import { AllEpic1 } from "./myEpics";
import { AuthReducer } from "./Reducers/AuthReducer";
import { ProfileReducer } from "./Reducers/ProfileReducer";
import { PropertyReducer } from "./Reducers/PropertyReducer";
import { AgentAgencyReducer } from "./Reducers/AgentAgencyReducer";

export const rootReducer = combineReducers({
  AuthReducer,
  ProfileReducer,
  AgentAgencyReducer,
  PropertyReducer,
});

export const rootEpic = combineEpics(AllEpic1, AllEpic);
