import React, { useState } from "react";
import { Button, Checkbox, Divider, Form, Radio } from "antd";
import { useEffect } from "react";

import DashboardLayout from "../../layout/Layout";
import Breadcrumb from "../../component/breedcrumb/Breadcrumb";
import { allActions } from "../../Redux/myActions";
import { useDispatch, connect } from "react-redux";
import MainLoader from "../../component/loader/MainLoader";
import { CloseCircleOutlined } from "@ant-design/icons";
import Autocomplete from "react-google-autocomplete";

const crumbList = [
  {
    title: "Dashboard",
    link: "/dashboard",
  },
  {
    title: "Inquiry Setting",
    link: "/find-agent-inquiry/setting",
  },
];

const mapStateToProps = (store) => {
  const { AgentAgencyReducer } = store;
  return { AgentAgencyReducer };
};

const InquirySetting = ({ AgentAgencyReducer }) => {
  const dispatch = useDispatch();
  const { agentInquirySetting, loading } = AgentAgencyReducer;
  const [value, setValue] = useState(false);
  const [form] = Form.useForm();
  const [locationList, setLocationList] = useState([]);

  useEffect(() => {
    fetchInquirySetting();
  }, []);

  const fetchInquirySetting = () => {
    dispatch(
      allActions(
        {},
        {
          method: "get",
          endPoint: "enquiry_settings",
          attempt: "FETCH_INQUIRY_SETTING_REQUEST_ATTEMPT",
          success: "FETCH_INQUIRY_SETTING_REQUEST_SUCCESS",
          failure: "FETCH_INQUIRY_SETTING_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: (res) => {
            setLocationList(
              res?.setting_locations?.map((item) => item?.location)
            );
          },
          failureInternalState: () => {},
        }
      )
    );
  };

  const handleUpdateInquirySetting = (values) => {
    dispatch(
      allActions(
        {
          all_aus: value ? "1" : "0",
          inquiry_for: values?.inquiry_for,
          property_nature: values?.property_nature,
          property_condition: values?.property_condition,
          locations: locationList,
        },
        {
          method: "post",
          endPoint: "enquiry_settings",
          attempt: "POST_INQUIRY_SETTING_REQUEST_ATTEMPT",
          success: "POST_INQUIRY_SETTING_REQUEST_SUCCESS",
          failure: "POST_INQUIRY_SETTING_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: () => {
            fetchInquirySetting();
            setValue(false);
          },
          failureInternalState: () => {},
        }
      )
    );
  };

  return (
    <DashboardLayout>
      {loading && <MainLoader />}
      <Breadcrumb title="Inquiry Setting" crumbList={crumbList} />
      <div className="inquiry--setting--container">
        <h3>Inquriy Setting</h3>
        <Divider style={{ margin: "0.5rem 0" }} />

        {agentInquirySetting && (
          <Form
            initialValues={{
              inquiry_for: agentInquirySetting?.setting?.inquiry_for,
              property_nature: agentInquirySetting?.setting?.nature_of_property,
              property_condition:
                agentInquirySetting?.setting?.property_condition,
              locations: "",
            }}
            form={form}
            layout="vertical"
            onFinish={handleUpdateInquirySetting}
          >
            <Form.Item
              rules={[
                {
                  required: value || locationList?.length === 0 ? true : false,
                  message: "Property address is required",
                },
              ]}
              name="locations"
              label={
                <span style={{ fontSize: "1rem", fontWeight: "500" }}>
                  Location of the property?
                </span>
              }
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                {!value && (
                  <Autocomplete
                    apiKey="AIzaSyAKLUx_rnltQ2u9Xr39DcpX3UdRr293gCU"
                    style={{
                      width: "90%",
                      outline: "none",
                      height: "2rem",
                      border: "1px solid #D8D9DA",
                      padding: "0 0.5rem",
                    }}
                    onPlaceSelected={(place) => {
                      setLocationList((prev) => [
                        ...prev,
                        place?.formatted_address,
                      ]);
                    }}
                    options={{
                      types: ["(regions)"],
                      componentRestrictions: { country: "au" },
                    }}
                  />
                )}

                <Checkbox
                  checked={value}
                  onChange={() => setValue((prev) => !prev)}
                  style={{ flexBasis: "40%" }}
                >
                  All over Australia
                </Checkbox>
              </div>
            </Form.Item>
            {locationList?.length > 0 && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                  maxHeight: "6rem",
                  overflowY: "scroll",
                  padding: "1rem",
                  backgroundColor: "#f5f5f5",
                  marginBottom: "1rem",
                }}
              >
                {locationList?.map((loc, index) => (
                  <div
                    key={`${loc} ${index}`}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      gap: "0.5rem",
                    }}
                  >
                    {loc}{" "}
                    <CloseCircleOutlined
                      onClick={() =>
                        setLocationList(
                          locationList?.filter((item) => item !== loc)
                        )
                      }
                      style={{
                        cursor: "pointer",
                        color: "red",
                        fontSize: "1.2rem",
                      }}
                    />{" "}
                  </div>
                ))}
              </div>
            )}
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Inquiry for value is required",
                },
              ]}
              label={
                <span style={{ fontSize: "1rem", fontWeight: "500" }}>
                  Inquiry for
                </span>
              }
              name="inquiry_for"
            >
              <Radio.Group
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  width: "100%",
                  gap: "1rem",
                }}
                options={agentInquirySetting?.enquiry_for?.map((item) => {
                  return {
                    label: item,
                    value: item,
                  };
                })}
              />
            </Form.Item>

            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Nature of Property value is required",
                },
              ]}
              label={
                <span style={{ fontSize: "1rem", fontWeight: "500" }}>
                  Nature of Property
                </span>
              }
              name="property_nature"
            >
              <Radio.Group
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  width: "100%",
                  gap: "1rem",
                }}
                options={[
                  ...agentInquirySetting?.property_nature?.map((item) => {
                    return {
                      label: item,
                      value: item,
                    };
                  }),
                ]}
              />
            </Form.Item>

            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Property Condition value is required",
                },
              ]}
              label={
                <span style={{ fontSize: "1rem", fontWeight: "500" }}>
                  Property Conditions
                </span>
              }
              name="property_condition"
            >
              <Radio.Group
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  width: "100%",
                  gap: "1rem",
                }}
                options={[
                  ...agentInquirySetting?.property_condition?.map((item) => {
                    return {
                      label: item,
                      value: item,
                    };
                  }),
                ]}
              />
            </Form.Item>

            <Button
              type="primary"
              style={{
                width: "100%",
                margin: "2rem 0 0",
                borderRadius: "2rem",
              }}
              htmlType="submit"
              className="button--modal inquiry--setting--button"
            >
              Update
            </Button>
          </Form>
        )}
      </div>
    </DashboardLayout>
  );
};

export default connect(mapStateToProps)(InquirySetting);
