import axios from "axios";

export const axiosApi = axios.create({
  // baseURL: "https://re.classibazaar.au/api/v3/",
  // baseURL: "https://classibazaar.com.au/api/v2/",
  baseURL: "https://classibazaar.com.au/cbazaar_api/api/v3/",
});

export const axiosApi1 = axios.create({
  baseURL: "https://classibazaar.com/api/v1/",
});
