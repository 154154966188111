import React, { useState } from "react";
import { Input, Form } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { useDispatch, connect } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";

import { allActions } from "../../Redux/myActions";
import LoaderPrimary from "../../component/loader/LoaderPrimary";

import logo3 from "../../assets/classiEstateAdmin3.png";

const mapStateToProps = (store) => {
  const AuthReducer = store.AuthReducer;
  return AuthReducer;
};

const Signup = (AuthReducer) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const { loading } = AuthReducer;

  const handleSubmitSignup = (values) => {
    dispatch(
      allActions(
        {
          first_name: values.firstName,
          last_name: values.lastName,
          email: values.email,
          password: values.password,
        },
        {
          method: "post",
          endPoint: "signup",
          attempt: "SIGNUP_REQUEST_ATTEMPT",
          success: "SIGNUP_REQUEST_SUCCESS",
          failure: "SIGNUP_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: () => {
            navigate("/verify-otp", { state: { isSuccessOtp: true } });
            localStorage.setItem("userEmail", values.email);
          },
          failureInternalState: () => {},
        }
      )
    );
  };

  return (
    <div className="signup--container">
      <div className="signup--container--body">
        {loading && <LoaderPrimary />}
        <img
          src={logo3}
          style={{
            padding: "0.3rem",
            width: "300px",
            height: "auto",
          }}
          alt="classiEstate Logo"
        />
        <p className="signup--header">Signup a new Account!</p>
        <Form
          layout="vertical"
          className="form--container"
          onFinish={handleSubmitSignup}
        >
          <div className="form--inner--container">
            <Form.Item
              className="signup--input"
              name="firstName"
              rules={[
                {
                  required: true,
                  message: (
                    <span style={{ padding: "0.5rem" }}>
                      FirstName is required
                    </span>
                  ),
                },
              ]}
            >
              <Input
                className="signup--input"
                size="large"
                type="text"
                placeholder="First Name"
                prefix={<UserOutlined className="site-form-item-icon" />}
              />
            </Form.Item>
            <Form.Item
              className="signup--input"
              name="lastName"
              rules={[
                {
                  required: true,
                  message: (
                    <span style={{ padding: "0.5rem" }}>
                      LastName is required
                    </span>
                  ),
                },
              ]}
            >
              <Input
                className="signup--input"
                size="large"
                type="text"
                placeholder="Last Name"
                prefix={<UserOutlined className="site-form-item-icon" />}
              />
            </Form.Item>
          </div>

          <Form.Item
            className="signup--input"
            name="email"
            rules={[
              {
                required: true,
                message: (
                  <span style={{ padding: "0.5rem" }}>Email is required</span>
                ),
              },
            ]}
          >
            <Input
              className="signup--input"
              size="large"
              type="email"
              placeholder="Email"
              prefix={<UserOutlined className="site-form-item-icon" />}
            />
          </Form.Item>
          <Form.Item
            className="signup--input"
            name="password"
            rules={[
              {
                required: true,
                message: (
                  <span style={{ padding: "0.5rem" }}>
                    Password is required
                  </span>
                ),
              },
            ]}
          >
            <Input.Password
              className="signup--input"
              size="large"
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder="Password"
              visibilityToggle={{
                visible: passwordVisible,
                onVisibleChange: setPasswordVisible,
              }}
            />
          </Form.Item>
          <button
            disabled={loading}
            type="submit"
            //  className="signup--button"
            className="button--modal"
            style={{ border: "none" }}
          >
            SIGN UP
          </button>
        </Form>

        <p>
          Have an account?
          <NavLink to="/" className="signup--text">
            {" "}
            Signin
          </NavLink>
        </p>
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(Signup);
