import { Button, message } from "antd";
import React, { useState } from "react";
import SingleImageLayout from "./imageLayout/SingleImageLayout";
import TwoImageLayout from "./imageLayout/TwoImageLayout";
import ThreeImageLayout from "./imageLayout/ThreeImageLayout";
import FourImageLayout from "./imageLayout/FourImageLayout";
import { useDispatch, useSelector } from "react-redux";
import { allActions } from "../../../Redux/myActions";
import MainLoader from "../../../component/loader/MainLoader";

export default function PreviewFlyerTemplate({
  formData,
  imageList,
  setFormData,
  setCurrent,
}) {
  const dispatch = useDispatch();
  const { flyerloading } = useSelector((store) => store.PropertyReducer);
  const [open, setOpen] = useState(false);
  const [flyerPdf, setFlyerPdf] = useState("");

  const handleBack = () => {
    setFormData((prev) => prev);
    setCurrent((prev) => prev - 1);
  };

  const handlePDFLinkClick = (event) => {
    event.preventDefault();
    window.open(flyerPdf, "_blank");
  };

  const handleSaveFlyerPdf = () => {
    dispatch(
      allActions(
        {
          ...formData,
          property_image: imageList,
          agent_list: formData?.agent_list?.map((agent) => {
            return {
              name: `${agent?.first_name} ${agent?.last_name}`,
              phone: agent?.phone,
              mobile: agent?.mobile,
              photo: agent?.image_url,
              no_room: formData?.no_room ? formData?.no_room : 0,
              no_of_bathroom: formData?.no_of_bathroom
                ? formData?.no_of_bathroom
                : 0,
              no_of_car_space: formData?.no_of_car_space
                ? formData?.no_of_car_space
                : 0,
            };
          }),
        },
        {
          method: "post",
          endPoint: "flyers",
          attempt: "POST_FLYERS_REQUEST_ATTEMPT",
          success: "POST_FLYERS_REQUEST_SUCCESS",
          failure: "POST_FLYERS_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: (res) => {
            setOpen(true);
            setFlyerPdf(res?.flyers[0]?.url);
          },
          failureInternalState: () => {},
        }
      )
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        margin: "0 1rem",
      }}
    >
      {flyerloading && <MainLoader />}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "0.2rem",
          backgroundColor: "black",
        }}
      >
        <div
          className="template--header"
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "1rem",
            height: "4rem",
            marginBottom: "0.2rem",
          }}
        >
          <img
            alt=""
            src={formData?.logo}
            style={{
              width: "4rem",
              height: "4rem",
              objectFit: "contain",
              border: "1px solid white",
            }}
          />
        </div>
        <div style={{ width: "100%" }}>
          {imageList?.length === 1 && (
            <SingleImageLayout imageList={imageList} />
          )}
          {imageList?.length === 2 && <TwoImageLayout imageList={imageList} />}
          {imageList?.length === 3 && (
            <ThreeImageLayout imageList={imageList} />
          )}
          {imageList?.length === 4 && <FourImageLayout imageList={imageList} />}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "0 1.5rem 1rem",
            gap: "0.5rem",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "flex-end",
              gap: "1rem",
            }}
          >
            <h1 style={{ color: "#eed988" }}>{formData?.suburb}</h1>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
                color: "white",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "1rem",
                  padding: "1rem 1rem 0",
                  borderRight: "2px solid white",
                  borderLeft: "2px solid white",
                }}
              >
                <span>{formData?.no_room}</span>
                <span>ROOM</span>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "1rem",
                  padding: "1rem 1rem 0",
                }}
              >
                <span>{formData?.no_of_bathroom}</span>
                <span>BATH</span>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "1rem",
                  padding: "1rem 1rem 0",
                  borderRight: "2px solid white",
                  borderLeft: "2px solid white",
                }}
              >
                <span>{formData?.no_of_car_space}</span>
                <span>CAR</span>
              </div>
            </div>
          </div>
          <h2 style={{ color: "white" }}>{formData?.property_street_name}</h2>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "2fr 1fr",
              gap: "1.5rem",
            }}
          >
            <h3 style={{ color: "white" }}>{formData?.description}</h3>
            <h2
              style={{
                color: "#eed988",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              For {formData?.property_type?.toUpperCase()}
            </h2>
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "2fr 1fr",
              gap: "1.5rem",
              marginTop: "1rem",
            }}
          >
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "0.5rem",
              }}
            >
              {formData?.agent_list?.map((item) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "1rem",
                  }}
                >
                  <img
                    src={item?.image_url}
                    alt=""
                    style={{
                      width: "4rem",
                      height: "4rem",
                      objectFit: "cover",
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <h2
                      style={{
                        color: "#eed988",
                      }}
                    >
                      {item?.first_name} {item?.last_name}
                    </h2>
                    <h1
                      style={{
                        color: "white",
                      }}
                    >
                      {item?.phone}
                    </h1>
                  </div>
                </div>
              ))}
            </div>
            <h1
              style={{
                color: "#eed988",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {formData?.phone}
            </h1>
          </div>
        </div>
      </div>

      <div style={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
        <Button
          style={{
            width: "fit-content",
          }}
          type="primary"
          onClick={handleBack}
        >
          Back
        </Button>
        {open ? (
          <Button
            style={{
              width: "fit-content",
            }}
            type="primary"
            onClick={(e) => handlePDFLinkClick(e)}
          >
            Download
          </Button>
        ) : (
          <Button
            onClick={handleSaveFlyerPdf}
            style={{
              width: "fit-content",
            }}
            type="primary"
          >
            Save
          </Button>
        )}
      </div>
    </div>
  );
}
