import { Drawer } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { allActions } from "../../../Redux/myActions";
import AdPackageComponent from "./AdPackageComponent";
import AdUpgradePayment from "./AdUpgradePayment";
import ScreenSize from "../../../component/screenSizeReader/ScreenSize";

export default function AdUpgradeDrawer({
  openUpgradeAdDrawer,
  setOpenUpgradeAdDrawer,
  postId,
}) {
  const dispatch = useDispatch();
  const drawerWidth = ScreenSize({ width: "85%" });
  const [current, setCurrent] = useState(0);
  const [planData, setPlanData] = useState({
    totalAmount: 0,
    selectedPlan: "",
    featureList: "",
  });

  useEffect(() => {
    fetchAdPaymentTypeDuration();
  }, []);

  const fetchAdPaymentTypeDuration = () => {
    dispatch(
      allActions(
        {},
        {
          method: "get",
          endPoint: `ads_payment_types_duration?titles[]=Featured&titles[]=Urgent&titles[]=Spotlight&titles[]=Logo`,
          attempt: "FETCH_AD_PAYMENT_TYPE_DURATION_REQUEST_ATTEMPT",
          success: "FETCH_AD_PAYMENT_TYPE_DURATION_REQUEST_SUCCESS",
          failure: "FETCH_AD_PAYMENT_TYPE_DURATION_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: () => {},
          failureInternalState: () => {},
        }
      )
    );
  };

  const formComponent = [
    <AdPackageComponent setCurrent={setCurrent} setPlanData={setPlanData} />,
    <AdUpgradePayment
      setCurrent={setCurrent}
      planData={planData}
      postId={postId}
      setOpenUpgradeAdDrawer={setOpenUpgradeAdDrawer}
    />,
  ];

  return (
    <Drawer
      title="Upgrade Your Property"
      onClose={() => setOpenUpgradeAdDrawer(false)}
      open={openUpgradeAdDrawer}
      width={drawerWidth}
    >
      {formComponent[current]}
    </Drawer>
  );
}
