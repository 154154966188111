import React from "react";

const ConcentricCircle = () => {
  return (
    <div className="outer--circle--container">
      <div className="inner--circle--container"></div>
    </div>
  );
};

export default ConcentricCircle;
