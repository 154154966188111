import React, { useEffect } from "react";

import DashboardLayout from "../../layout/Layout";
import Breadcrumb from "../../component/breedcrumb/Breadcrumb";
import { Button, DatePicker, Form, Table } from "antd";
import { useDispatch, connect } from "react-redux";
import { allActions } from "../../Redux/myActions";
import MainLoader from "../../component/loader/MainLoader";
import moment from "moment";

const crumbList = [
  {
    title: "Dashboard",
    link: "/dashboard",
  },
  {
    title: "Agency Inquiry",
    link: "/offer-message/agency-inquiry",
  },
];
const mapStateToProps = (store) => {
  const AgentAgencyReducer = store?.AgentAgencyReducer;
  return { AgentAgencyReducer };
};
const AgencyInquiryPage = ({ AgentAgencyReducer }) => {
  const { loading, agencyInquiry } = AgentAgencyReducer;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      allActions(
        {},
        {
          method: "get",
          endPoint: "agency_inquiry",
          attempt: "FETCH_AGENCY_INQUIRYlIST_REQUEST_ATTEMPT",
          success: "FETCH_AGENCY_INQUIRYlIST_REQUEST_SUCCESS",
          failure: "FETCH_AGENCY_INQUIRYlIST_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: (res) => {},
          failureInternalState: () => {},
        }
      )
    );
  }, []);

  return (
    <DashboardLayout>
      {loading && <MainLoader />}
      <Breadcrumb title="Agency Inquiry" crumbList={crumbList} />
      <div className="agency--inquiry--page--container">
        <AgencyInquiryHeaderContainer />
        <AgencyInquriyBodyContainer agencyInquiry={agencyInquiry} />
      </div>
    </DashboardLayout>
  );
};

export default connect(mapStateToProps)(AgencyInquiryPage);

export const AgencyInquiryHeaderContainer = () => {
  return (
    <div className="agency--inquiry--container--header">
      <Form className="agency--inquiry--search--field" layout="horizontal">
        <Form.Item
          className="agentinqury--field--wrapper"
          style={{ margin: "0" }}
          name="from_date"
        >
          <DatePicker
            className="date--picker--agentinqury"
            format="DD-MM-YYYY"
            placeholder="From Date"
            size="large"
          />
        </Form.Item>

        <Form.Item
          style={{ margin: "0" }}
          className="agentinqury--field--wrapper"
          name="to_date"
        >
          <DatePicker
            placeholder="To Date"
            className="date--picker--agentinqury"
            format="DD-MM-YYYY"
            size="large"
          />
        </Form.Item>

        <div>
          <Button
            size="large"
            className="button--modal--primary"
            htmlType="submit"
          >
            Search
          </Button>
        </div>
      </Form>
    </div>
  );
};

export const AgencyInquriyBodyContainer = ({ agencyInquiry }) => {
  //   const data = [
  //     {
  //       title: "",
  //     },
  //   ];
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      align: "center",
    },
    {
      title: "Message",
      dataIndex: "message",
      align: "center",
    },
    {
      title: "Name",
      dataIndex: "name",
      align: "center",
    },
    {
      title: "Email",
      align: "center",
      dataIndex: "email",
    },
    {
      title: "Phone",
      align: "center",
      dataIndex: "phone",
    },
    {
      title: "Date",
      align: "center",
      dataIndex: "added_date",
      render: (date) => <span>{moment(date)?.format("DD-MM-YYYY")}</span>,
    },
  ];
  return (
    // <div className="agency--inquiry--container--body">
    <Table
      scroll={{ y: 400, x: 800 }}
      // style={{ overflow: "scroll" }}
      bordered
      dataSource={agencyInquiry?.enquiries}
      className="agency--inquiry--container--body"
      columns={columns}
    />
    // </div>
  );
};
