import {
  Drawer,
  Upload,
  Input,
  Form,
  InputNumber,
  Select,
  message,
  Button,
} from "antd";
import { useEffect, useRef, useState } from "react";
import { MobileFilled, UserOutlined } from "@ant-design/icons";

import ScreenSize from "../screenSizeReader/ScreenSize";
import { useDispatch, connect } from "react-redux";
import { allActions } from "../../Redux/myActions";
import { validateInputNumber } from "../../Helpers/FrontendHelper";
import MainLoader from "../loader/MainLoader";

const mapStateToProps = (store) => {
  const AgentAgencyReducer = store?.AgentAgencyReducer;
  return { AgentAgencyReducer };
};

const EditAgentDrawer = ({
  agentDrawer,
  setAgentDrawer,
  agentData,
  loading,
  handleFetchAgent,
  AgentAgencyReducer,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const imageRef = useRef();
  const drawerWidth = ScreenSize({ width: "75%" });
  const [imageUser, setImage] = useState("");
  const [imageFile, setImageFile] = useState("");
  const [isImageUpdate, setIsImageUpdate] = useState(false);

  const { agentloading, agentimageloading, agentImage } = AgentAgencyReducer;

  useEffect(() => {
    form.setFieldsValue({
      first_name: agentData?.first_name,
      last_name: agentData?.last_name,
      email: agentData?.email,
      phone: agentData?.phone,
      mobile: agentData?.mobile,
      fax: agentData?.fax,
      agent_status: agentData?.agent_status,
    });
    setImage(agentData?.image_url);
  }, [agentData]);

  const onClose = () => {
    setAgentDrawer(false);
  };

  const handleSubmitNewAgent = (values) => {
    dispatch(
      allActions(
        {
          ...values,
          image_url: isImageUpdate ? agentImage[0]?.image_name : imageUser,
          thumbnail: isImageUpdate ? agentImage[0]?.image_name : imageUser,
        },
        {
          method: "post",
          endPoint: `agent/edit_agent/${agentData?.id}`,
          attempt: "EDIT_AGENT_REQUEST_ATTEMPT",
          success: "EDIT_AGENT_REQUEST_SUCCESS",
          failure: "EDIT_AGENT_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: () => {
            setTimeout(() => {
              handleFetchAgent();
              setAgentDrawer(false);
            }, 1000);
          },
          failureInternalState: () => {},
        }
      )
    );
  };

  const handleFileSelected = (event) => {
    const form = new FormData();
    form.append("files[]", event.target.files[0]);

    dispatch(
      allActions(form, {
        method: "post",
        endPoint: "upload_post_image",
        attempt: "UPLOAD_AGENT_IMAGE_REQUEST_ATTEMPT",
        success: "UPLOAD_AGENT_IMAGE_REQUEST_SUCCESS",
        failure: "UPLOAD_AGENT_IMAGE_REQUEST_FAILURE",

        saveBearerToken: false,
        multipartFormData: true,
        successInternalState: (res) => {
          setIsImageUpdate(true);
          setImage(res?.source[0]?.image_name);
        },
        failureInternalState: () => {},
      })
    );
  };

  const handleClickImage = (e) => {
    imageRef.current.click();
  };

  return (
    <Drawer
      title="Edit Agent Detail"
      placement="right"
      onClose={onClose}
      open={agentDrawer}
      width={drawerWidth}
    >
      {agentimageloading && <MainLoader />}
      <Form
        form={form}
        onFinish={handleSubmitNewAgent}
        labelAlign="left"
        layout="vertical"
        className="add--agent--form--container"
      >
        <div className="add--agent--container">
          <Form.Item
            className="agent--child--container"
            label="First Name"
            name="first_name"
            rules={[
              {
                required: true,
                message: "First Name is required",
              },
            ]}
          >
            <Input placeholder="Enter First Name" />
          </Form.Item>
          <Form.Item
            className="agent--child--container"
            label="Last Name"
            name="last_name"
            rules={[
              {
                required: true,
                message: "Last Name is required",
              },
            ]}
          >
            <Input placeholder="Enter Last Name" />
          </Form.Item>
        </div>
        <div className="add--agent--container">
          <Form.Item
            className="agent--child--container"
            name="email"
            label="Email"
            labelAlign="left"
            rules={[
              {
                required: true,
                message: "Email is required",
              },
            ]}
          >
            <Input type="email" placeholder="Enter Email" />
          </Form.Item>
          <Form.Item
            className="agent--child--container"
            label="Status"
            name="agent_status"
            labelAlign="left"
          >
            <Select
              placeholder="Select status"
              options={[
                {
                  value: "active",
                  label: "Active",
                },
                {
                  value: "inactive",
                  label: "Inactive",
                },
              ]}
            ></Select>
          </Form.Item>
        </div>

        <div className="add--agent--container">
          <Form.Item
            className="agent--child--container"
            label="Phone Number"
            name="phone"
            rules={[
              {
                required: true,
                message: "Phone Number is required",
              },
              { validator: validateInputNumber },
            ]}
          >
            <InputNumber
              type="number"
              style={{ width: "100%" }}
              placeholder="e.g 9712353412"
            />
          </Form.Item>
          <Form.Item
            className="agent--child--container"
            label="Mobile Number"
            name="mobile"
            rules={[
              {
                required: true,
                message: "Mobile Number is required",
              },
              { validator: validateInputNumber },
            ]}
          >
            <InputNumber
              type="number"
              style={{ width: "100%" }}
              placeholder="Enter mobile number"
            />
          </Form.Item>
        </div>
        <div className="add--agent--container">
          <Form.Item
            className="agent--child--container"
            label="Upload Image"
            labelAlign="left"
            style={{
              position: "relative",
            }}
          >
            <input
              style={{ display: "none" }}
              ref={imageRef}
              type="file"
              accept=".png, .jpg, .jpeg"
              onChange={handleFileSelected}
            />
            {imageUser ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                  gap: "0.5rem",
                }}
              >
                <img
                  src={imageUser}
                  alt="img"
                  style={{
                    width: "5rem",
                    height: "5rem",
                    borderRadius: "0.5rem",
                    border: "1px solid gray",
                  }}
                />
              </div>
            ) : (
              <UserOutlined
                style={{
                  fontSize: "5rem",
                  borderRadius: "0.5rem",
                  border: "1px solid gray",
                  color: "gray",
                }}
              />
            )}
            <Button
              type="primary"
              className="upload--button"
              onClick={handleClickImage}
            >
              {/* Upload */}
            </Button>
          </Form.Item>
          <Form.Item className="agent--child--container" label="FAX" name="fax">
            <InputNumber
              type="number"
              style={{ width: "100%" }}
              placeholder="Enter FAX"
            />
          </Form.Item>
        </div>

        <Form.Item>
          <Button
            className="button--modal"
            type="primary"
            loading={loading}
            htmlType="submit"
          >
            {loading ? "Loading..." : "Edit Agent"}
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default connect(mapStateToProps)(EditAgentDrawer);
