import React, { useEffect } from "react";
import Marquee from "react-fast-marquee";

import DashboardLayout from "../../layout/Layout";
import Breadcrumb from "../../component/breedcrumb/Breadcrumb";
import { Alert, Divider } from "antd";
import { allActions } from "../../Redux/myActions";
import { useDispatch, connect } from "react-redux";
import { UserSwitchOutlined, CreditCardOutlined } from "@ant-design/icons";

const crumbList = [
  {
    title: "Dashboard",
    link: "/dashboard",
  },
  {
    title: "Referral Board",
    link: "/referral/ref-dashboard",
  },
];

const mapStateToProps = (store) => {
  const { AgentAgencyReducer } = store;
  return { AgentAgencyReducer };
};

const ReferralDashboard = ({ AgentAgencyReducer }) => {
  const dispatch = useDispatch();
  const { loading, referLoginUserList, classiCreidtList, creditloading } =
    AgentAgencyReducer;

  useEffect(() => {
    FetchReferLoginUser();
    fetchClassiCredit();
  }, []);

  const FetchReferLoginUser = () => {
    dispatch(
      allActions(
        {},
        {
          method: "get",
          endPoint: "referal_login_friends",
          attempt: "FETCH_REFERAL_LOGIN_USER_REQUEST_ATTEMPT",
          success: "FETCH_REFERAL_LOGIN_USER_REQUEST_SUCCESS",
          failure: "FETCH_REFERAL_LOGIN_USER_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: () => {},
          failureInternalState: () => {},
        }
      )
    );
  };

  const fetchClassiCredit = () => {
    dispatch(
      allActions(
        {},
        {
          method: "get",
          endPoint: "classi_credits",
          attempt: "FETCH_CLASSI_CREDIT_REQUEST_ATTEMPT",
          success: "FETCH_CLASSI_CREDIT_REQUEST_SUCCESS",
          failure: "FETCH_CLASSI_CREDIT_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: () => {},
          failureInternalState: () => {},
        }
      )
    );
  };
  return (
    <DashboardLayout>
      <Breadcrumb title="Referral Dashboard" crumbList={crumbList} />
      {}
      <Alert
        type="info"
        showIcon
        style={{
          marginTop: "1rem",
          height: "3.5rem",
          fontSize: "1rem",
          fontWeight: "600",
          color: "#0b3f77ce",
          border: "none",
          borderRadius: "0",
        }}
        message={
          <Marquee pauseOnHover gradient={false}>
            Hurry up! Share the classibazaar invitation to your friend and get
            100 Classi credit on sign up of 5 friends. Invite Friends
          </Marquee>
        }
      />
      <div className="referral--dashboard--container">
        <div className="referral--bashboard--innercontainer">
          <h3 style={{ fontWeight: "500", padding: "1rem" }}>Login Friends</h3>
          <Divider style={{ margin: "0" }} />
          <div className="login--user--container">
            {referLoginUserList?.map((user) => {
              return (
                <div className="refer--user--container" key={user}>
                  <div>
                    <UserSwitchOutlined style={{ fontSize: "2rem" }} />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <span style={{ fontWeight: 600 }}>
                      {" "}
                      {user?.first_name} {user?.last_name}
                    </span>
                    <span>{user?.user_email}</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="referral--bashboard--innercontainer">
          <h3 style={{ fontWeight: "500", padding: "1rem" }}>
            Classi Credits ( AUD 1 = 10 Classi Credits)
          </h3>
          <Divider style={{ margin: "0" }} />
          <div className="login--user--container">
            {classiCreidtList?.map((credit) => {
              return (
                <div className="refer--user--container" key={credit}>
                  <div>
                    <CreditCardOutlined style={{ fontSize: "2rem" }} />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <span style={{ fontWeight: 600 }}>
                      Credit: {credit?.credit_number}
                    </span>
                    <span>At: {credit?.credit_date}</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default connect(mapStateToProps)(ReferralDashboard);
