import { useEffect, useState } from "react";
const CurrentScreenSize = () => {
  const [isLarge, setIsLarge] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 767) {
        setIsLarge(true);
      } else {
        setIsLarge(false);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial width calculation

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return isLarge;
};

export default CurrentScreenSize;
