import React from "react";

export default function TwoImageLayout({ imageList }) {
  return (
    <div className="two--image--layout">
      {imageList?.map((image) => (
        <img
          alt=""
          src={image}
          style={{ height: "25rem", width: "100%", objectFit: "cover" }}
        />
      ))}
    </div>
  );
}
