import React from "react";

export default function SingleImageLayout({ imageList }) {
  return (
    <div>
      {imageList?.map((image) => (
        <img
          alt=""
          src={image}
          style={{ height: "25rem", width: "100%", objectFit: "cover" }}
        />
      ))}
    </div>
  );
}
