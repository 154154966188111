import React from "react";

const LoaderPrimary = () => {
  return (
    <div className="loader--container">
      <span className="loader--body"></span>
    </div>
  );
};

export default LoaderPrimary;
