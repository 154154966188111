import { Drawer } from "antd";
import React from "react";

export default function FlyerPdf({ active, setActive, pdf, setPdf }) {
  const onClose = () => {
    setActive(false);
    setPdf("");
  };

  return (
    <Drawer
      open={active}
      title="Flyer Template"
      placement="right"
      onClose={onClose}
      width="1000"
    >
      <iframe src={pdf} width="100%" height="800" />
    </Drawer>
  );
}
