export const allActions = (
  payload = {},
  type = {
    method: "post",
    endPoint: "delivery-user/login",
    attempt: "LOGIN_FETCHING_DATA_ATTEMPT",
    success: "LOGIN_FETCHING_DATA_SUCCESS",
    failure: "LOGIN_FETCHING_DATA_FAILURE",
    navigateTo: "ABC",

    isToken: "",
    saveBearerToken: false,
    multipartFormData: false,
    successInternalState: () => {},
    failureInternalState: () => {},
  }
) => ({ type: type.attempt, type_data: type, payload });
