import { useEffect, useState } from "react";
const ScreenSize = ({ width }) => {
  const [drawerWidth, setDrawerWidth] = useState("100%");

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setDrawerWidth(width);
      } else {
        setDrawerWidth("100%");
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial width calculation

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return drawerWidth;
};

export default ScreenSize;
