import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Button, Divider, Form, InputNumber } from "antd";

import DashboardLayout from "../../layout/Layout";
import { PostAnAd } from "../../component";
import { allActions } from "../../Redux/myActions";
import Breadcrumb from "../../component/breedcrumb/Breadcrumb";
import MainLoader from "../../component/loader/MainLoader";

const mapStateToProps = (store) => {
  const PropertyReducer = store?.PropertyReducer;
  return PropertyReducer;
};

const AdProperty = (PropertyReducer) => {
  const dispatch = useDispatch();
  const [openPost, setOpenPost] = useState(false);
  const { catA, realEstateData, loading, selectcatloading } = PropertyReducer;
  const realestatedata = JSON.parse(localStorage.getItem("realestatedata"));
  const [phoneModal, setPhoneModal] = useState(true);

  const crumbList = [
    {
      title: "Dashboard",
      link: "/dashboard",
    },
    {
      title: "Post an Ad",
      link: "/ad-property",
    },
  ];

  useEffect(() => {
    dispatch(
      allActions(
        {},
        {
          method: "get",
          endPoint: "search_categories",
          attempt: "SEARCH_CATEGORY_REQUEST_ATTEMPT",
          success: "SEARCH_CATEGORY_REQUEST_SUCCESS",
          failure: "SEARCH_CATEGORY_REQUEST_FAILURE",
          saveBearerToken: false,
          successInternalState: () => {},
          failureInternalState: () => {},
        }
      )
    );
  }, []);

  const handleOpenPostAnAd = () => {
    dispatch(
      allActions(
        {},
        {
          method: "get",
          endPoint: `post_categories?parent_id=${catA?.cat_id}`,
          attempt: "CATB_REQUEST_ATTEMPT",
          success: "CATB_REQUEST_SUCCESS",
          failure: "CATB_REQUEST_FAILURE",
          isToken: false,
          saveBearerToken: false,
          successInternalState: () => {
            setOpenPost(true);
          },
          failureInternalState: () => {},
        }
      )
    );
    dispatch(
      allActions(
        {},
        {
          method: "get",
          endPoint: "profile_new",
          attempt: "PROFILE_REQUEST_ATTEMPT",
          success: "PROFILE_REQUEST_SUCCESS",
          failure: "PROFILE_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: () => {},
          failureInternalState: () => {},
        }
      )
    );
  };

  const handleSubmitPhoneNumber = (values) => {
    dispatch(
      allActions(values, {
        method: "post",
        endPoint: "verify_mobile",
        attempt: "VERIFY_MOBILE_REQUEST_ATTEMPT",
        success: "VERIFY_MOBILE_REQUEST_SUCCESS",
        failure: "VERIFY_MOBILE_REQUEST_FAILURE",
        saveBearerToken: false,
        successInternalState: () => {
          setTimeout(() => {
            setPhoneModal((prev) => !prev);
          }, 1000);
        },
        failureInternalState: () => {},
      })
    );
  };

  const handleSubmitOtpCode = (values) => {
    dispatch(
      allActions(
        {
          code: values?.code,
          status: 1,
        },
        {
          method: "post",
          endPoint: "add_code",
          attempt: "VERIFY_MOBILE_OTPCODE_REQUEST_ATTEMPT",
          success: "VERIFY_MOBILE_OTPCODE_REQUEST_SUCCESS",
          failure: "VERIFY_MOBILE_OTPCODE_REQUEST_FAILURE",
          saveBearerToken: false,
          successInternalState: () => {
            refetchRealStateData();
          },
          failureInternalState: () => {},
        }
      )
    );
  };

  const refetchRealStateData = () => {
    dispatch(
      allActions(
        {},
        {
          method: "get",
          endPoint: "realstate_profile",
          attempt: "REALESTATE_PROFILE_REQUEST_ATTEMPT",
          success: "REALESTATE_PROFILE_REQUEST_SUCCESS",
          failure: "REALESTATE_PROFILE_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: () => {
            setTimeout(() => {
              setPhoneModal((prev) => !prev);
            }, 1000);
          },
          failureInternalState: () => {},
        }
      )
    );
  };

  return (
    <DashboardLayout>
      {(selectcatloading || loading) && <MainLoader />}
      <Breadcrumb title="Post An Ad" crumbList={crumbList} />

      <div className="ad--property--layout">
        {Number(realestatedata?.mobile_verified) !== 1 ? (
          <div className="ad--property--container">
            <h3 style={{ textAlign: "center" }}>Ad Detail</h3>
            <Divider style={{ margin: "0.5rem 0" }} />
            <span className="button--modal" onClick={handleOpenPostAnAd}>
              Select Category Here
            </span>
          </div>
        ) : (
          <>
            {phoneModal ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
                className="ad--property--container"
              >
                <h3>Verfiy your Phone Number</h3>
                <Divider style={{ margin: "0.5rem 0" }} />
                <Form layout="vertical" onFinish={handleSubmitPhoneNumber}>
                  <Form.Item
                    label="Mobile Number"
                    name="mobile_number"
                    rules={[
                      {
                        required: true,
                        message: "Phone Number is required",
                      },
                    ]}
                  >
                    <InputNumber type="number" style={{ width: "100%" }} />
                  </Form.Item>
                  <Button
                    style={{ width: "100%", borderColor: "white" }}
                    htmlType="submit"
                    className="button--modal"
                  >
                    {loading ? "Loading..." : "Submit"}
                  </Button>
                </Form>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
                className="ad--property--container"
              >
                <h3>Verfiy your OTP code</h3>
                <Divider style={{ margin: "0.5rem 0" }} />
                <Form layout="vertical" onFinish={handleSubmitOtpCode}>
                  <Form.Item
                    label="OTP code"
                    name="code"
                    rules={[
                      {
                        required: true,
                        message: "OTP Code is required",
                      },
                    ]}
                  >
                    <InputNumber type="number" style={{ width: "100%" }} />
                  </Form.Item>
                  <Button
                    // loading={loading}
                    style={{ width: "100%", borderColor: "white" }}
                    htmlType="submit"
                    className="button--modal"
                  >
                    Submit
                    {/* {loading ? "Loading..." : "Submit"} */}
                  </Button>
                </Form>
              </div>
            )}
          </>
        )}
        {openPost && <PostAnAd open={openPost} setOpen={setOpenPost} />}
      </div>
    </DashboardLayout>
  );
};

export default connect(mapStateToProps)(AdProperty);
