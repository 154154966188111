import { Form, Input, InputNumber } from "antd";
import React from "react";
import { connect } from "react-redux";
import { usePlacesWidget } from "react-google-autocomplete";

const mapStateToProps = (store) => {
  const { PropertyReducer, ProfileReducer } = store;
  return { PropertyReducer, ProfileReducer };
};

const PostAdForm3 = ({ form }) => {
  const { ref } = usePlacesWidget({
    apiKey: "AIzaSyAKLUx_rnltQ2u9Xr39DcpX3UdRr293gCU",

    onPlaceSelected: (place) => {
      form.setFieldsValue({ location: place?.formatted_address });
      place?.address_components?.map((item) => {
        if (item.types.includes("postal_code")) {
          form.setFieldsValue({
            postcode: item?.long_name,
          });
        }
        return null;
      });
    },
    options: {
      types: ["address"],
      componentRestrictions: { country: "au" },
    },
  });
  return (
    <>
      <h3 style={{ margin: "1rem 0" }}>Contact Detail</h3>
      <div
        style={{
          borderRadius: "1rem",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div className="two--row--container">
          <Form.Item
            rules={[
              {
                required: true,
                message: "First Name is required",
              },
            ]}
            className="two--row--child--container"
            label="First Name"
            name="first_name"
          >
            <Input placeholder="Enter First Name" />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Last Name is required",
              },
            ]}
            className="two--row--child--container"
            label="Last Name"
            name="last_name"
          >
            <Input placeholder="Enter Last Name" />
          </Form.Item>
        </div>
        <div className="two--row--container">
          <Form.Item
            rules={[
              {
                required: true,
                message: "Email is required",
              },
            ]}
            className="two--row--child--container"
            label="Email"
            name="email"
          >
            <Input type="email" placeholder="Enter Email" />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Phone Number is required",
              },
            ]}
            className="two--row--child--container"
            label="Phone Number"
            name="phone"
          >
            <InputNumber
              style={{ width: "100%" }}
              placeholder="e.g 9871232441"
            />
          </Form.Item>
        </div>
        <div className="two--row--container">
          <Form.Item
            rules={[
              {
                required: true,
                message: "Address is required",
              },
            ]}
            label="Address"
            name="location"
            className="two--row--child--container"
          >
            <input
              ref={ref}
              style={{
                width: "100%",
                height: "2rem",
                outline: "none",
                borderRadius: "0.5rem",
                paddingLeft: "0.5rem",
                border: "1px solid  #c0c2c980",
              }}
              placeholder="Enter the Address"
            />
          </Form.Item>
        </div>
      </div>
    </>
  );
};

export default connect(mapStateToProps)(PostAdForm3);
